import { PortalDialogProps } from "@app/services/modal.service";
import Modal from "../modal/modal.component";

interface NotificationModalProps {
  title: string;
  content: string;
}

function NotificationModal({
  portalDialogRef,
  portalData,
}: PortalDialogProps<NotificationModalProps>) {
  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren>
      <div className="w-[500px] max-sm:w-[300px] h-auto max-h-96 overflow-y-auto p-6">
        <div className="text-lg text-center font-bold">{portalData?.title}</div>
        <div
          className="mt-3"
          dangerouslySetInnerHTML={{ __html: portalData?.content ?? "" }}
        ></div>
      </div>
    </Modal>
  );
}

export default NotificationModal;
