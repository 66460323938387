import { useNavigate } from "react-router-dom";
import "./not-found.style.scss";

function NotFound() {
  const navigate = useNavigate();

  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>Oops!</h1>
          <h2>404 - The Page can&apos;t be found</h2>
        </div>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            navigate("/");
          }}
        >
          Go TO Homepage
        </a>
      </div>
    </div>
  );
}

export default NotFound;
