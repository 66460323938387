import { DEFAULT_PAGE, DEFAULT_TOTAL_PAGES } from "@app/constants";
import useObservable from "@core/hooks/use-observable.hook";
import { useEffect, useState } from "react";  
import Pagination from "@app/components/pagination";
import { UserGuide } from "@app/types";
import UserGuideService from "@app/services/http/user.guide.service";

function UserGuideManagement() {
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [totalPages, setTotalPages] = useState(DEFAULT_TOTAL_PAGES);
  const [userGuide, setUserGuide] = useState<UserGuide[]>([]);

  const { subscribeUntilDestroy } = useObservable();

  useEffect(() => {
    subscribeUntilDestroy(
      UserGuideService.getUserGuides(page),
      (userGuides) => {
        if (userGuides.data.length) {
          setUserGuide(userGuides.data);
          setTotalPages(userGuides.total_page);
        } else {
          setUserGuide([]);
          setTotalPages(DEFAULT_TOTAL_PAGES);
        }
      }
    );
  }, [page]);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  return (
    <div className="w-full mx-auto mt-4 pb-4">
      <div className="pb-2 px-2 border-b border-[#409EFF]">
        {/* <div className="flex justify-end">
          <Button
            label="Thêm Hướng Dẫn"
            width="fit-content"
            className="px-2 text-sm"
            onClick={handleCreateUserGuide}
          />
        </div> */}
      </div>
      <div className="px-2 overflow-x-auto mt-2">
        <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            {/* <thead className="text-white">
              <tr className="border-b-2 border-gray-200 bg-[#409EFF] text-sm">
                <th className="p-3 text-left w-40">ID</th>
                <th className="p-3 text-left w-40">TITLE</th>
                <th className="p-3 text-left w-40">LINK</th>
                <th className="p-3 text-left w-40">CREATED AT</th>
              </tr>
            </thead> */}
            <tbody>
              {!!userGuide.length &&
                userGuide.map((userGuide) => (
                  <tr
                    key={userGuide._id}
                    className="border-b border-gray-200 text-sm hover:bg-[#51b83c2c]"
                  >
                    <td className="p-3">
                      <a target="blank" href={userGuide.link}>
                        {userGuide.title}
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {!userGuide.length && (
            <div className="flex justify-center">Không có dữ liệu</div>
          )}
        </div>
      </div>
      {totalPages > 0 && (
        <div className="flex justify-center mt-3">
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </div>
  );
}

export default UserGuideManagement;
