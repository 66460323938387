import { Package } from "@app/types";
import { formatMoneyVN } from "@app/utils/util";
import { Images } from "@assets/images";
import clsx from "clsx";

interface ImageCardProps {
  item: Package;

  isSelected: boolean;

  onItemClick: (item: Package) => void;
}

function ImageCard({ item, isSelected, onItemClick }: ImageCardProps) {
  return (
    <div
      className={clsx(
        "relative min-w-[300px] max-w-[300px] max-md:!min-w-[270px] max-sm:!min-w-[250px] max-[400px]:!min-w-[200px] shadow-[0_2px_12px_0_rgba(0,0,0,10%)]",
        " border-[2px] border-solid border-transparent rounded-t-[20px]",
        {
          "!border-[#64B5F6]": isSelected,
        },
        "max-md:text-[8px]"
      )}
      onClick={() => onItemClick(item)}
    >
      <div
        className={clsx("w-full py-1 rounded-t-2xl", {
          "bg-[#64B5F6]": isSelected,
        })}
      >
        <img
          className="w-[50px] mx-auto"
          src={Images.LogoPcRenderWhite.default}
          alt=""
        />
      </div>
      <div className="px-4 py-2 cursor-pointer">
        <div className="max-md:text-xs font-[Montserrat] font-bold text-lg">
          {item.name}
        </div>
        <div className="max-md:text-xs font-[Montserrat] font-bold text-lg">
          {item.is_available ? (
            <div className="text-[#388E3C]">Server Ready</div>
          ) : (
            <div className="text-[#ff4848]">Server Full</div>
          )}
        </div>
        <div className="max-md:text-xs font-[Montserrat] text-[14.4px] flex items-center gap-2">
          <span className="max-md:text-xs text-[#1976D2] text-lg">
            {item.price_per_hour && formatMoneyVN(item.price_per_hour)}
          </span>{" "}
          VNĐ/hour
        </div>
        <div className="max-md:text-xs font-[Montserrat] text-[14.4px] flex items-center gap-2">
          <span className="max-md:text-xs text-[#1976D2] text-lg">
            {item.price_per_day && formatMoneyVN(item.price_per_day)}
          </span>{" "}
          VNĐ/day
        </div>
        <div className="max-md:text-xs font-[Montserrat] text-[14.4px] flex items-center gap-2">
          <span className="max-md:text-xs text-[#1976D2] text-lg">
            {item.price_per_month && formatMoneyVN(item.price_per_month)}
          </span>{" "}
          VNĐ/month
        </div>
        <div className="flex items-center gap-1">
          <div className="text-[#1976D2]">•</div>
          <div className="max-md:text-[8px] text-[12.8px] text-[#303133] font-semibold">
            GPU:
          </div>
          <div className="text-[#388E3C] font-[Montserrat] font-bold">
            {item.gpu}
          </div>
        </div>
        <div className="flex items-center gap-1">
          <div className="text-[#1976D2]">•</div>
          <div className="max-md:text-[8px] text-[12.8px] text-[#303133] font-semibold">
            CPU:
          </div>
          <div className="max-md:text-[8px] text-[12.8px] font-[Montserrat]">
            {item.cpu}
          </div>
        </div>
        <div className="flex items-center gap-1">
          <div className="text-[#1976D2]">•</div>
          <div className="max-md:text-[8px] text-[12.8px] text-[#303133] font-semibold">
            RAM:
          </div>
          <div className="max-md:text-[8px] text-[12.8px] font-[Montserrat]">
            {item.ram}
          </div>
        </div>
        <div className="flex items-center gap-1">
          <div className="text-[#1976D2]">•</div>
          <div className="max-md:text-[8px] text-[12.8px] text-[#303133] font-semibold">
            SSD:
          </div>
          <div className="max-md:text-[8px] text-[12.8px] font-[Montserrat]">
            {item.ssd}
          </div>
        </div>
        <div className="flex items-center gap-1">
          <div className="text-[#1976D2]">•</div>
          <div className="max-md:text-[8px] text-[12.8px] text-[#303133] font-semibold">
            OS:
          </div>
          <div className="max-md:text-[8px] text-[12.8px] font-[Montserrat]">
            {item.os}
          </div>
        </div>
        <div className="flex items-center gap-1">
          <div className="text-[#1976D2]">•</div>
          <div className="max-md:text-[8px] text-[12.8px] text-[#303133] font-semibold">
            Box Phone:
          </div>
          <div className="max-md:text-[8px] text-[12.8px] font-[Montserrat]">
            {item.box_phone}
          </div>
        </div>
        <div className="flex items-center gap-1">
          <div className="text-[#1976D2]">•</div>
          <div className="max-md:text-[8px] text-[12.8px] text-[#303133] font-semibold">
            Support:
          </div>
          <div className="max-md:text-[8px] text-[12.8px] font-[Montserrat]">
            {item.support}
          </div>
        </div>
        <div className="mt-1 flex gap-2 items-center">
          <div>
            <img
              className="max-md:w-4 w-7"
              src={Images.RedshiftIcon.default}
              alt=""
            />
          </div>
          <div>
            <img
              className="max-md:w-4 w-7"
              src={Images.BlenderIcon.default}
              alt=""
            />
          </div>
          <div>
            <img
              className="max-md:w-4  w-7"
              src={Images.OctaneIcon.default}
              alt=""
            />
          </div>
          <div>
            <img
              className="max-md:w-4 w-7"
              src={Images.IrayIcon.default}
              alt=""
            />
          </div>
          <div className="font-[Montserrat] text-[#303133]">etc.</div>
        </div>
      </div>
    </div>
  );
}

export default ImageCard;
