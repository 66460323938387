import { PortalDialogProps } from "@app/services/modal.service";
import Modal from "../modal/modal.component";
import Input from "../input";
import Button from "../button";
import { Form, Formik, FormikContextType } from "formik";
import { bootImageValidationSchema } from "@app/validations";
import { FormControl } from "../form-control";
import { Select } from "../select/select";
import { createRef, useState } from "react";
import { BootImage, ImageItem } from "@app/types";
import {
  BOOT_IMAGE_UNIT_OPTIONS,
  BootImageUnit,
  BootImageUnitName,
} from "@app/constants";
import { formatMoneyVN } from "@app/utils/util";

interface BootImageModalProps {
  image: ImageItem;
  is_extends?: boolean;
}

function BootImageModal({
  portalDialogRef,
  portalData,
}: PortalDialogProps<BootImageModalProps>) {
  const formRef = createRef<FormikContextType<BootImage>>();

  const [unit, setUnit] = useState(
    portalData?.is_extends
      ? (portalData?.image.unit as BootImageUnit)
      : BootImageUnit.HOUR
  );
  const [amount, setAmount] = useState(0);

  const onBootClick = ({ rentalPeriod, unit }: BootImage) => {
    portalDialogRef.close({ rentalPeriod, unit });
  };

  const getMoneyPayment = (unit: BootImageUnit, amount: number) => {
    if (!portalData?.image) {
      return 0;
    }

    switch (unit) {
      case BootImageUnit.HOUR:
        return amount * portalData.image.package.price_per_hour;
      case BootImageUnit.DAY:
        return amount * portalData.image.package.price_per_day;
      case BootImageUnit.MONTH:
        return amount * portalData.image.package.price_per_month;
      default:
        return 0;
    }
  };

  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren>
      <div className="w-[400px] px-8 py-5">
        <div className="font-bold text-xl">
          {portalData?.is_extends ? "Gia hạn" : "Boot Image"}
        </div>
        <Formik
          initialValues={{
            unit: portalData?.is_extends
              ? (portalData?.image.unit as BootImageUnit)
              : BootImageUnit.HOUR,
            rentalPeriod: 0,
          }}
          validationSchema={bootImageValidationSchema}
          innerRef={formRef}
          onSubmit={onBootClick}
          validateOnChange
          validateOnBlur
        >
          <Form>
            <div className="mt-4 text-sm">
              {portalData?.is_extends && (
                <div className="text-[#f34848]">
                  Lưu ý: Đơn vị gia hạn phải cùng đơn vị mà bạn thuê trước đó
                </div>
              )}
              <div className="flex gap-2 items-center">
                <div>Chọn đơn vị:</div>
                <FormControl name="unit">
                  <Select
                    width={95}
                    className="h-6 !mt-0"
                    options={
                      portalData?.is_extends
                        ? [
                            {
                              label:
                                BootImageUnitName[
                                  (portalData.image.unit as BootImageUnit) ||
                                    unit
                                ],
                              value: portalData.image.unit,
                            },
                          ]
                        : BOOT_IMAGE_UNIT_OPTIONS
                    }
                    onChange={(e) => setUnit(e.target.value as BootImageUnit)}
                  />
                </FormControl>
              </div>
              <div className="mt-4">Nhập số {BootImageUnitName[unit]}:</div>
              <FormControl name="rentalPeriod">
                <Input
                  className="mt-1 !max-w-none text-sm"
                  type="number"
                  errorClassName="text-sm"
                  onChange={(e) => setAmount(Number(e.target.value))}
                />
              </FormControl>
              <div className="mt-4">
                Số tiền phải trả: {formatMoneyVN(getMoneyPayment(unit, amount))}
              </div>
              <div className="text-[#f34848]">
                Lưu ý: Không hoàn tiền khi đã thuê máy ,vui lòng chọn đúng thời
                gian sử dụng!
              </div>
            </div>
            <div className="mt-5">
              <Button
                label={portalData?.is_extends ? "Gia hạn" : "Boot"}
                type="submit"
                width="fit-content"
                containerClassName="mx-auto"
                className="px-10 !bg-[#409EFF] hover:!bg-[#66B1FF] !rounded-[20px]"
                labelClassName="font-medium text-sm"
              />
            </div>
          </Form>
        </Formik>
      </div>
    </Modal>
  );
}

export default BootImageModal;
