import { RouterProvider } from "react-router-dom";
import ModalContainer from "./components/modal/modal-container";
import ToastContainer from "./components/toast/toast.container";
import router from "./router";
import "./styles/app.scss";
import Loading from "@core/components/loading";
import { useEffect } from "react";
import useObservable from "@core/hooks/use-observable.hook";
import AccountService from "./services/http/account.service";
import { Subscription, catchError, throwError } from "rxjs";
import { useDispatch, useSelector } from "react-redux";
import { clearUser } from "./store/auth/auth.action";
import { clearMyInfo, storeMyInfo } from "./store/my-info/my-info.action";
import SocketService from "./services/socket.service";
import { RechargeStatusCode, SocketEvent } from "./constants";
import { GlobalState } from "./store";
import { addToast } from "./components/toast/toast.service";

function App() {
  const dispatch = useDispatch();

  const { myInfo } = useSelector(selectMyInfo);
  const { subscribeUntilDestroy } = useObservable();

  const subscription = new Subscription();

  useEffect(() => {
    subscribeUntilDestroy(
      AccountService.getMyInfo().pipe(
        catchError((err) => {
          dispatch(clearUser());
          dispatch(clearMyInfo());

          return throwError(() => err);
        })
      ),
      (data) => {
        dispatch(storeMyInfo(data));
      }
    );
  }, []);

  useEffect(() => {
    SocketService.connect();
  }, []);

  useEffect(() => {
    if (myInfo._id) {
      SocketService.emit(SocketEvent.JOIN_ROOM, myInfo._id);
    }
  }, [myInfo._id]);

  useEffect(() => {
    const sub = SocketService.onRechargeResult().subscribe((data) => {
      if (data.statusCode === RechargeStatusCode.FAILED) {
        addToast({ text: data.message, status: "inValid" });
      }

      if (data.statusCode === RechargeStatusCode.SUCCESS) {
        addToast({ text: data.message });

        subscribeUntilDestroy(
          AccountService.getMyInfo().pipe(
            catchError((err) => {
              dispatch(clearUser());
              dispatch(clearMyInfo());

              return throwError(() => err);
            })
          ),
          (accountRes) => {
            dispatch(storeMyInfo(accountRes));
          }
        );
      }
    });

    subscription.add(sub);
  }, [subscription]);

  useEffect(() => () => subscription.unsubscribe(), [subscription]);

  return (
    <>
      <RouterProvider router={router} />
      <div id="toast-root">
        <ToastContainer />
      </div>
      <div id="modal-root">
        <ModalContainer />
      </div>
      <div id="loading-root">
        <Loading />
      </div>
    </>
  );
}

const selectMyInfo = (state: GlobalState) => state.myInfo;

export default App;
