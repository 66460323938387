import { createBrowserRouter } from "react-router-dom";
import SignIn from "./pages/sign-in";
import SignUp from "./pages/sign-up";
import MainLayout from "./layout/main.layout";
import Home from "./pages/home";
import Api from "./pages/api";
import PaymentHistory from "./pages/payment-history";
import { isAuthenticated } from "./loaders/authentication";
import { PathnameUrl } from "./constants";
import GpuHub from "./pages/gpu-hub";
import IdentityVerification from "./pages/identity-verification";
import LogManagement from "./pages/log-management";
import OrderManagement from "./pages/order-management";
import NotFound from "./pages/not-found";
import UserGuideManagement from "./pages/user-guide-management";
import VpsRental from "./pages/vps-rental";
import OrderVpsManagement from "./pages/order-vps-management";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    loader: isAuthenticated,
    children: [
      {
        path: "",
        element: <GpuHub />,
      },
      {
        path: "api",
        element: <Api />,
      },
      {
        path: PathnameUrl.VPS_RENTAL,
        element: <VpsRental />,
      },
      {
        path: PathnameUrl.RECHARGE,
        element: <PaymentHistory />,
      },
      {
        path: PathnameUrl.IDENTITY_VERIFICATION,
        element: <IdentityVerification />,
      },
      {
        path: PathnameUrl.ORDER_MANAGEMENT,
        element: <OrderManagement />,
      },
      {
        path: PathnameUrl.ORDER_VPS_MANAGEMENT,
        element: <OrderVpsManagement />,
      },
      {
        path: PathnameUrl.LOG_MANAGEMENT,
        element: <LogManagement />,
      },
      {
        path: PathnameUrl.USER_GUIDE_MANAGEMENT,
        element: <UserGuideManagement />,
      },
    ],
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/login",
    element: <SignIn />,
    loader: isAuthenticated,
  },
  {
    path: "/register",
    element: <SignUp />,
    loader: isAuthenticated,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

export default router;
