import MenuLinkItem from "@app/components/menu-link-item";
import {
  ACCESS_TOKEN_KEY,
  MenuAdmin,
  PathnameUrl,
  USER_INFO_KEY,
} from "@app/constants";
import { GlobalState } from "@app/store";
import { clearUser } from "@app/store/auth/auth.action";
import { formatMoneyVN } from "@app/utils/util";
import { Images } from "@assets/images";
import StorageService from "@core/services/storage";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import clsx from "clsx";

function MainLayout() {
  const [isShowActions, setIsShowActions] = useState(false);
  const [isShowSidebar, setIsShowSidebar] = useState(false);

  const { myInfo } = useSelector(selectMyInfo);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    dispatch(clearUser());
    StorageService.remove(ACCESS_TOKEN_KEY);
    StorageService.remove(USER_INFO_KEY);
    navigate("/login");
  };

  const onMenuItemClick = () => {
    setIsShowSidebar(!isShowSidebar);
  };

  return (
    <>
      <div>
        <div className="sticky top-0 left-0 w-full h-20 max-sm:h-12 bg-white flex justify-between px-4 z-10 border-b border-solid border-[#eee]">
          <div className="h-full items-center flex">
            <div>
              <img
                className="md:hidden w-8 max-sm:w-6 cursor-pointer"
                src={Images.MenuIcon.default}
                alt=""
                onClick={() => setIsShowSidebar(!isShowSidebar)}
              />
              <img
                className="max-md:hidden w-[180px] cursor-pointer"
                src={Images.LogoPcRender.default}
                alt=""
                onClick={() => navigate(0)}
              />
            </div>
          </div>
          <div className="h-full items-center flex">
            <div className="flex h-full mr-5">
              <NavLink
                to="/home"
                className={({ isActive }) => {
                  let className =
                    "px-4 h-full flex items-center text-[#3A4664] hover:bg-[#ecf5ff] max-md:hidden";

                  if (isActive) {
                    className +=
                      "bg-[#F2F8FF] border-b-[3px] border-solid text-[#42A5F5] border-[#42A5F5] pt-[3px]";
                  }

                  return className;
                }}
                onClick={() => navigate("/home")}
              >
                Trang chủ
              </NavLink>
              <NavLink
                to="/api"
                className={({ isActive }) => {
                  let className =
                    "px-4 h-full flex items-center text-[#3A4664] hover:bg-[#ecf5ff] max-md:hidden";

                  if (isActive) {
                    className +=
                      "bg-[#F2F8FF] border-b-[3px] border-solid text-[#42A5F5] border-[#42A5F5] pt-[3px]";
                  }

                  return className;
                }}
                onClick={() => {}}
              >
                API
              </NavLink>
            </div>
            <div className="mr-5 w-fit">
              <div className="text-sm flex justify-end items-center gap-1">
                <div className="text-[#42A5F5]">
                  {formatMoneyVN(myInfo.balance)} VNĐ
                </div>
                <div>
                  <i className="fa fa-coins w-[11px] h-[11px]"></i>
                </div>
              </div>
            </div>
            <div className="mr-5">
              <div className="rounded-full bg-[#eee] w-9 h-9 max-sm:w-6 max-sm:h-6 flex items-center justify-center cursor-pointer">
                <i className="fa fa-bell w-4 h-4 text-[#606266]"></i>
              </div>
            </div>
            <div className="mr-3 flex flex-col max-md:hidden">
              <div>{myInfo.username}</div>
              <div className="text-xs text-[#42A5F5]">{myInfo.email}</div>
              <div className="text-xs text-[#42A5F5]">{myInfo.phone}</div>
              <div className="text-xs text-[#42A5F5]">{myInfo.fullName}</div>
            </div>
            <div className="cursor-pointer relative">
              <img
                className="w-12 h-12 max-sm:w-8 max-sm:h-8"
                src={Images.AvatarDefaultIcon.default}
                alt=""
                onClick={() => setIsShowActions(!isShowActions)}
              />
              {isShowActions && (
                <div className="absolute top-14 max-sm:top-10 right-0 w-[119px] max-md:w-[150px] bg-white text-sm shadow-[0_4px_18px_0_rgb(0,0,0,15%)] rounded-[3px]">
                  <div className="flex flex-col md:hidden p-1 break-words">
                    <div>{myInfo.username}</div>
                    <div className="text-xs text-[#42A5F5]">{myInfo.email}</div>
                    <div className="text-xs text-[#42A5F5]">{myInfo.phone}</div>
                    <div className="text-xs text-[#42A5F5]">
                      {myInfo.fullName}
                    </div>
                  </div>
                  <div
                    className="p-1 cursor-pointer hover:bg-[#1E86E5] hover:text-white rounded-b-[3px] text-[#3A4664]"
                    onClick={logout}
                  >
                    Đăng xuất
                  </div>
                  <div className="p-1 cursor-pointer hover:bg-[#1E86E5] hover:text-white rounded-b-[3px] text-[#3A4664]">
                    <a
                      href="https://api.pcrender.com/view/confirm-account"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Đổi mật khẩu
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="max-md:ml-0 ml-[260px]">
          <Outlet />
        </div>
      </div>
      <div
        className={clsx(
          "transition-all max-md:left-[-260px] fixed top-20 max-sm:top-12 left-0 w-[260px] h-[calc(100%-64px)] max-sm:h-[calc(100%-32px)] bg-white border-r-[2px] border-solid border-[#eee]",
          {
            "max-md:left-[0px]": isShowSidebar,
          }
        )}
      >
        <div className="md:hidden">
          <NavLink
            to="/home"
            onClick={onMenuItemClick}
            className={({ isActive }) => {
              let className = "h-14 px-6 flex items-center hover:bg-[#ecf5ff] ";

              if (isActive) {
                className +=
                  "text-[#409EFF] bg-[#ecf5ff] border-l-4 border-solid border-[#409EFF] !pl-[20px] ";
              }

              return className;
            }}
          >
            <div className="mr-[15px]">
              <img className="w-6" src={Images.HomeIcon.default} alt="" />
            </div>
            <div className="text-sm">Trang chủ</div>
          </NavLink>
        </div>
        {MenuAdmin.length &&
          MenuAdmin.map((item, index) => {
            if (
              item.linkTo === PathnameUrl.IDENTITY_VERIFICATION &&
              myInfo.kyc === 0
            ) {
              return;
            }

            return (
              <MenuLinkItem
                key={index}
                {...item}
                onMenuItemClick={onMenuItemClick}
              />
            );
          })}
      </div>
    </>
  );
}

const selectMyInfo = (state: GlobalState) => state.myInfo;

export default MainLayout;
