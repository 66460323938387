import { Category } from "@app/types";
import HttpService from "@core/services/http/http.service";

class _CategoryService {
  public getCategories() {
    return HttpService.get<Category[]>("/categories");
  }
}

const CategoryService = new _CategoryService();

export default CategoryService;
