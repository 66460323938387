import {
  PortalDialogProps,
  openPortalDialog,
} from "@app/services/modal.service";
import Modal from "../modal/modal.component";
import { Form, Formik, FormikContextType } from "formik";
import { FormControl } from "../form-control";
import Input from "../input";
import Button from "../button";
import { CustomSelect } from "../select/custom-select";
import TriangleDownArrow from "../select/triangle-down-arrow";
import { createRechargeValidationSchema } from "@app/validations";
import { createRef, useEffect, useState } from "react";
import { NormalSelectOptions } from "../select/select.type";
import useObservable from "@core/hooks/use-observable.hook";
import BankService from "@app/services/http/bank.service";
import { CreateRechargeRequest, RechargeInitialValues } from "@app/types";
import RechargeService from "@app/services/http/recharge.service";
import Countdown from "react-countdown";
import { RECHARGE_COUNTDOWN_TIME } from "@app/constants";

function QRInfoModal({ portalDialogRef, portalData }: PortalDialogProps) {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren={false}>
      <div className="p-5">
        <Countdown
          date={Date.now() + RECHARGE_COUNTDOWN_TIME}
          renderer={({ minutes, seconds }) => (
            <span className="text-red-500 font-medium">
              Thời gian còn lại: {`0${minutes}`.slice(-2)}:
              {`0${seconds}`.slice(-2)}
            </span>
          )}
          onComplete={() => {
            portalDialogRef.close({ isCompleted: true });
          }}
        />

        <div>{isImageLoaded ? "" : "Chờ hiển thị tài khoản..."}</div>

        <img
          width={450}
          src={portalData?.qrCode}
          onLoad={() => {
            setIsImageLoaded(true); // Khi ảnh được tải xong, cập nhật trạng thái để ẩn div
          }}
          alt="Chờ hiển thị tài khoản..."
        />
        <div className="flex justify-center gap-5">
          <Button
            label="Hủy nạp"
            theme="danger"
            onClick={() => {
              portalDialogRef.close({ isCancel: true });
            }}
          />
          <Button
            label="Hoàn tất"
            onClick={() => {
              portalDialogRef.close({ isCompleted: true });
            }}
          />
        </div>
      </div>
    </Modal>
  );
}

function CreateRechargeModal({ portalDialogRef }: PortalDialogProps) {
  const formRef = createRef<FormikContextType<RechargeInitialValues>>();

  const [banks, setBanks] = useState<NormalSelectOptions>([]);

  const { subscribeOnce, subscribeUntilDestroy } = useObservable();

  useEffect(() => {
    subscribeUntilDestroy(BankService.getBanks(), (banks) => {
      if (banks.length) {
        const banksDropdown: NormalSelectOptions = banks.map((bank) => ({
          label: bank.bank_name,
          value: bank._id,
        }));

        setBanks(banksDropdown);
      } else {
        setBanks([]);
      }
    });
  }, []);

  const handleNextStep = (values: RechargeInitialValues) => {
    const createRechargeRequest: CreateRechargeRequest = {
      money: values.money,
      bank_id: values.bankId,
    };

    subscribeOnce(
      RechargeService.createRecharge(createRechargeRequest),
      (res) => {
        if (res.link_payment) {
          const qrInfoModalObs = openPortalDialog(QRInfoModal, {
            qrCode: res.link_payment,
          });

          qrInfoModalObs.afterClosed().subscribe((data) => {
            if (data?.isCancel) {
              subscribeOnce(RechargeService.cancelRecharge(res._id), () => {
                portalDialogRef.close();
              });
            }

            if (data?.isCompleted) {
              portalDialogRef.close();
            }
          });
        }
      }
    );
  };

  const handleKeyDown = (e: any) => {
    // Ngăn chặn sự kiện submit khi nhấn space hoặc enter
    if (e.key === " " || e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren>
      <div
        onKeyDown={handleKeyDown}
        className="w-[400px] max-[400px]:w-[300px] p-5"
      >
        <div className="font-bold text-xl">Nạp tiền</div>
        <Formik
          initialValues={{ money: "", bankId: "" }}
          innerRef={formRef}
          onSubmit={handleNextStep}
          validationSchema={createRechargeValidationSchema}
          validateOnChange
          validateOnBlur
        >
          <Form>
            <div className="mt-3">
              <div className="text-sm">Số tiền</div>
              <FormControl name="money">
                <Input
                  className="mt-1 !max-w-none text-sm"
                  errorClassName="text-sm"
                />
              </FormControl>
            </div>
            <div className="mt-3">
              <div className="text-sm">Ngân hàng nhận</div>
              <FormControl name="bankId">
                <CustomSelect
                  options={banks}
                  width="100%"
                  className="!h-9 !rounded-lg"
                  optionContainerClassName="top-[34px] w-full"
                  optionClassName="!min-h-[35px] text-sm text-black whitespace-nowrap px-2"
                  labelSelectedClassName="text-black text-sm"
                  errorClassName="!text-[#D60000B2] text-sm"
                  arrow={<TriangleDownArrow />}
                  hasBgColorHover
                />
              </FormControl>
            </div>
            <Button
              type="submit"
              label="Tiếp tục"
              containerClassName="mt-5 mx-auto"
              width="fit-content"
              className="px-6 text-sm"
              labelClassName="font-bold"
            />
          </Form>
        </Formik>
      </div>
    </Modal>
  );
}

export default CreateRechargeModal;
