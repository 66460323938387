import { DEFAULT_DATE_TIME_FORMAT } from "@app/constants";
import dayjs from "dayjs";
import { Buffer } from "buffer";

export const formatMoneyVN = (
  money: number | string,
  options?: Intl.NumberFormatOptions
) => {
  return Intl.NumberFormat("vi-VN", options).format(Number(money));
};

export const generateQRCode = (
  bankName: string,
  bankNumber: string,
  bankOwner: string,
  money: string | number,
  content: string
) => {
  return `https://img.vietqr.io/image/${bankName}-${bankNumber}-print.png?amount=${money}&addInfo=${content}&accountName=${bankOwner}`;
};

export const randomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const formatDate = (
  dateTime: dayjs.ConfigType,
  format = DEFAULT_DATE_TIME_FORMAT
) => {
  return dayjs(dateTime).format(format);
};

export const downloadFile = (
  data: any,
  dataType?: string,
  fileName?: string
) => {
  const binaryData = [];

  binaryData.push(data);
  const downloadLink = document.createElement("a");

  downloadLink.href = window.URL.createObjectURL(
    new Blob(binaryData, { type: dataType })
  );

  if (fileName) downloadLink.setAttribute("download", fileName);

  downloadLink.click();
};

export const dataURLToFile = (dataURL: string, fileName: string) => {
  const arr = dataURL.split(",");
  const mime = arr[0]?.match(/:(.*?);/)?.[1];
  const bstr = Buffer.from(arr[arr.length - 1], "base64").toString("ascii");
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
};
