import { PortalDialogProps } from "@app/services/modal.service";
import Modal from "../modal/modal.component";
// import { ImageItem } from "@app/types";

function QRCodeModal({ portalDialogRef, portalData }: PortalDialogProps) {
  // const image: ImageItem = portalData;

  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren>
      <div className="w-fit p-4 flex flex-col justify-center items-center">
        <div
          dangerouslySetInnerHTML={{
            __html: portalData.qrcode.replace("\n", "").replace("512", "256"),
          }}
          style={{width: "fit-content"}}
        />
        <div>Tải và mở app wireguard ,quét mã QR code để kết nối</div>
      </div>
    </Modal>
  );
}

export default QRCodeModal;
