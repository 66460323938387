import * as Yup from "yup";

export const verifyValidationSchema = Yup.object().shape({
  fullName: Yup.string().required("Họ tên không được để trống"),
  dob: Yup.string().required("Ngày sinh không được để trống"),
  id: Yup.string().required("CCCD không được để trống"),
  issueDate: Yup.string().required("Ngày cấp không được để trống"),
  issuePlace: Yup.string().required("Nơi cấp không được để trống"),
  address: Yup.string().required("Địa chỉ không được để trống"),
  email: Yup.string().required("Email không được để trống"),
  phone: Yup.string().required("Số ĐT không được để trống"),
  isConfirmed: Yup.array().min(1, "Yêu cầu xác nhận"),
});
