import Input from "@app/components/input";
import Pagination from "@app/components/pagination";
import { Select } from "@app/components/select/select";
import {
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_PAGE,
  DEFAULT_TOTAL_PAGES,
  OrderExtendName,
  OrderStatus,
  OrderStatusName,
} from "@app/constants";
import OrderService from "@app/services/http/order.service";
import { Order } from "@app/types";
import { formatDate, formatMoneyVN } from "@app/utils/util";
import useObservable from "@core/hooks/use-observable.hook";
import dayjs from "dayjs";
import { ChangeEvent, useEffect, useState } from "react";
import { debounceTime, Subject } from "rxjs";

function OrderManagement() {
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [totalPages, setTotalPages] = useState(DEFAULT_TOTAL_PAGES);
  const [orders, setOrders] = useState<Order[]>([]);
  const [filterStatus, setFilterStatus] = useState<OrderStatus>();
  const [imageName, setImageName] = useState("");
  const [passRdp, setPassRdp] = useState("");
  const [remoteExtIp, setRemoteExtIp] = useState("");

  const imageNameSubject = new Subject<string>();
  const passRdpSubject = new Subject<string>();
  const remoteExtIpSubject = new Subject<string>();

  const { subscribeUntilDestroy } = useObservable();

  useEffect(() => {
    imageNameSubject.pipe(debounceTime(300)).subscribe((data) => {
      setImageName(data);
    });
    passRdpSubject.pipe(debounceTime(300)).subscribe((data) => {
      setPassRdp(data);
    });
    remoteExtIpSubject.pipe(debounceTime(300)).subscribe((data) => {
      setRemoteExtIp(data);
    });
  }, [imageNameSubject, passRdpSubject, remoteExtIpSubject]);

  useEffect(() => {
    subscribeUntilDestroy(
      OrderService.getOrders(
        page,
        imageName,
        passRdp,
        remoteExtIp,
        filterStatus
      ),
      (res) => {
        if (res.data.length) {
          setOrders(res.data);
          setTotalPages(res.total_page);
        } else {
          setOrders([]);
        }
      }
    );
  }, [page, filterStatus, imageName, passRdp, remoteExtIp]);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const handleFilterStatusChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setFilterStatus(e.target.value as any);
  };

  const onImageNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    imageNameSubject.next(e.target.value);
  };
  const onPassRdpChange = (e: ChangeEvent<HTMLInputElement>) => {
    passRdpSubject.next(e.target.value);
  };
  const onRemoteExtIpChange = (e: ChangeEvent<HTMLInputElement>) => {
    remoteExtIpSubject.next(e.target.value);
  };

  return (
    <div className="w-full mx-auto mt-4 pb-4">
      <div className="flex max-sm:flex-col items-center gap-5 ml-2">
        <Select
          placeholder="Trạng thái"
          onChange={handleFilterStatusChange}
          options={[
            { label: "Đang thuê", value: 1 },
            { label: "Hoàn thành", value: 0 },
          ]}
        />
        <Input
          className="max-w-[250px]"
          placeholder="Nhập Image Name"
          onChange={onImageNameChange}
        />
        <Input
          className="max-w-[250px]"
          placeholder="Nhập Pass Rdp"
          onChange={onPassRdpChange}
        />
        <Input
          className="max-w-[250px]"
          placeholder="Nhập Remote Ext IP"
          onChange={onRemoteExtIpChange}
        />
      </div>
      <div className="px-2 overflow-x-auto mt-2">
        <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead className="text-white">
              <tr className="border-b-2 border-gray-200 bg-[#409EFF] text-sm">
                <th className="p-3 text-left w-40">ID / IP Remote</th>
                <th className="p-3 text-left w-40">Name / Pass RDP</th>
                <th className="p-3 text-left w-40">Thời Gian Thuê</th>
                <th className="p-3 text-left w-40">Giá Tiền</th>
                <th className="p-3 text-left w-40">Start Time</th>
                <th className="p-3 text-left w-40">End Time</th>
                <th className="p-3 text-left w-40">Trạng Thái</th>
                <th className="p-3 text-left w-40">Gia Hạn</th>
                <th className="p-3 text-left w-40">Tiền Gia Hạn</th>
                <th className="p-3 text-left w-40">Tổng tiền</th>
              </tr>
            </thead>
            <tbody>
              {!!orders.length &&
                orders.map((order) => (
                  <tr
                    key={order._id}
                    className="border-b border-gray-200 text-sm hover:bg-[#51b83c2c]"
                  >
                    <td className="p-3">
                      {order._id} <br /> {order.remote_ext_ip}
                    </td>
                    <td className="p-3">
                      Name: {order.image.name} <br /> Pass: {order.passRdp}
                    </td>
                    <td className="p-3">{order.rental_period}h</td>
                    <td className="p-3">
                      {order.image.package.price_per_hour &&
                        formatMoneyVN(order.image.package.price_per_hour) +
                          "đ/h\n" +
                          formatMoneyVN(order.image.package.price_per_day) +
                          "đ/ngày\n" +
                          formatMoneyVN(order.image.package.price_per_month) +
                          "đ/tháng"}
                    </td>
                    <td className="p-3">{formatDate(order.time_boot)}</td>
                    <td className="p-3">
                      {order.end_time
                        ? formatDate(order.end_time)
                        : dayjs(order.time_boot)
                            .add(order.rental_period, "h")
                            .format(DEFAULT_DATE_TIME_FORMAT)}
                    </td>
                    <td className="p-3">{OrderStatusName[order.status]}</td>
                    <td className="p-3">{OrderExtendName[order.after_exp]}</td>
                    <td className="p-3">
                      {order.total_price && formatMoneyVN(order.total_price)}đ
                    </td>
                    <td className="p-3">
                      {order.total_price && formatMoneyVN(order.total_price)}đ
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {!orders.length && (
            <div className="flex justify-center">Không có dữ liệu</div>
          )}
        </div>
      </div>
      {totalPages > 0 && (
        <div className="flex justify-center mt-3">
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </div>
  );
}

export default OrderManagement;
