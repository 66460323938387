import {
  PortalDialogProps,
  openPortalDialog,
} from "@app/services/modal.service";
import Modal from "../modal/modal.component";
// import { ImageItem } from "@app/types";
import ConfirmModal from "../confirm-modal";
import { Images } from "@assets/images";

function TunnelModal({ portalDialogRef }: PortalDialogProps) {
  // const image: ImageItem = portalData;

  const handleClickWin = (os: string) => {
    const confirmDialog = openPortalDialog(ConfirmModal, {
      message: `Xác nhận chọn hệ điều hành: ${os}`,
    });

    confirmDialog.afterClosed().subscribe((data) => {
      if (data?.isAccept) {
        portalDialogRef.close({ os });
      }
    });
  };

  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren>
      <div className="w-[500px] p-4">
        <h1 className="text-center m-4 font-bold text-xl">
          Chọn cấu hình tải xuống của bạn
        </h1>
        <div className="flex gap-4 flex-wrap justify-center">
          <div
            className="w-[30%] text-center cursor-pointer bg-[#42A5F5] hover:bg-[#0f63a7] text-white py-1 px-2 rounded"
            onClick={() => handleClickWin("win")}
          >
            Window
            <img
              className="w-[35px] h-[35px] mx-auto"
              src={Images.Window.default}
              alt=""
            />
          </div>
          <div
            className="w-[30%] text-center cursor-pointer bg-[#42A5F5] hover:bg-[#0f63a7] text-white py-1 px-2 rounded"
            onClick={() => handleClickWin("mac")}
          >
            MacOS
            <img
              className="w-[35px] h-[35px] mx-auto"
              src={Images.MacOS.default}
              alt=""
            />
          </div>
          <div
            className="w-[30%] text-center cursor-pointer bg-[#42A5F5] hover:bg-[#0f63a7] text-white py-1 px-2 rounded"
            onClick={() => handleClickWin("mobile")}
          >
            Mobile
            <img
              className="w-[35px] h-[35px] mx-auto"
              src={Images.Smartphone.default}
              alt=""
            />
          </div>
          <div>
            Pcrender Tunnel là một mạng riêng ảo cho phép kết nối trục tiếp máy
            của bạn đến máy thuê.
            <br />
            Ngoài ra có thể giúp bạn bỏ qua NAT khi kết nối Parsec và giảm tới
            1/3 độ trễ.
            <br />
            Để sử dụng tính năng này, vui lòng chọn hệ điều hành tương ứng mà
            bạn đang sử dụng để tải về cài đặt.
            <br />
            <div className="text-red-500">
              Lưu ý : Trước khi tải , cài đặt Pcrender Tunnel cần tắt diệt virus
              của hệ điều hành bạn đang sử dụng
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default TunnelModal;
