import Button from "@app/components/button";
import Pagination from "@app/components/pagination";
import CreateRechargeModal from "@app/components/recharge-modal";
import {
  DEFAULT_PAGE,
  DEFAULT_TOTAL_PAGES,
  RechargeState,
  RechargeStateName,
} from "@app/constants";
import RechargeService from "@app/services/http/recharge.service";
import { openPortalDialog } from "@app/services/modal.service";
import { Recharge } from "@app/types";
import { formatDate, formatMoneyVN } from "@app/utils/util";
import useObservable from "@core/hooks/use-observable.hook";
import { useEffect, useState } from "react";
import clsx from "clsx";
import useForceUpdate from "@core/hooks/use-force-update.hook";
import { Subscription } from "rxjs";
import SocketService from "@app/services/socket.service";

function PaymentHistory() {
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [totalPages, setTotalPages] = useState(DEFAULT_TOTAL_PAGES);
  const [recharges, setRecharges] = useState<Recharge[]>([]);
  const [update, forceUpdate] = useForceUpdate();

  const { subscribeUntilDestroy } = useObservable();

  const subscription = new Subscription();

  useEffect(() => {
    subscribeUntilDestroy(RechargeService.getRecharges(page), (res) => {
      if (res.data.length) {
        setRecharges(res.data);
        setTotalPages(res.total_page);
      } else {
        setRecharges([]);
        setTotalPages(0);
      }
    });
  }, [page, update]);

  useEffect(() => {
    const sub = SocketService.onRechargeResult().subscribe(() => {
      forceUpdate();
    });

    subscription.add(sub);
  }, [subscription]);

  useEffect(() => () => subscription.unsubscribe(), [subscription]);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const handleRecharge = () => {
    const createRechargeModalObs = openPortalDialog(CreateRechargeModal);

    createRechargeModalObs.afterClosed().subscribe(() => {
      forceUpdate();
    });
  };

  return (
    <div className="w-full mx-auto mt-4 pb-4">
      <div className="pb-2 px-2 border-b border-[#409EFF]">
        <div className="flex justify-end">
          <Button
            label="Nạp tiền"
            width="fit-content"
            className="px-2 text-sm"
            onClick={handleRecharge}
          />
        </div>
      </div>
      <div className="px-2 overflow-x-auto mt-2">
        <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead className="text-white">
              <tr className="border-b-2 border-gray-200 bg-[#409EFF] text-sm">
                <th className="p-3 text-left w-72">Số tiền (VNĐ)</th>
                <th className="p-3 text-left w-96">Nội dung</th>
                <th className="p-3 text-left w-72">Ngân hàng nhận</th>
                <th className="p-3 text-left w-72">STK</th>
                <th className="p-3 text-left w-72">Chủ TK</th>
                <th className="p-3 text-left w-72">Thời gian nạp</th>
                <th className="p-3 text-left w-72">Trạng thái</th>
              </tr>
            </thead>
            <tbody>
              {!!recharges.length &&
                recharges.map((recharge) => (
                  <tr
                    key={recharge._id}
                    className="border-b border-gray-200 text-sm hover:bg-[#51b83c2c]"
                  >
                    <td className="p-3">
                      {recharge.money && formatMoneyVN(recharge.money)}
                    </td>
                    <td className="p-3">{recharge.content}</td>
                    <td className="p-3">{recharge.bank}</td>
                    <td className="p-3">
                      {recharge.link_payment?.split("-")[1] ||
                        "Không có dữ liệu"}
                    </td>
                    <td className="p-3">
                      {new URLSearchParams(recharge.link_payment).get(
                        "accountName"
                      ) || "Không có dữ liệu"}
                    </td>
                    <td className="p-3">{formatDate(recharge.createdAt)}</td>
                    <td className="p-3">
                      <span
                        className={clsx("px-2 py-1 rounded-lg", {
                          "bg-yellow-500":
                            recharge.is_succeeded === RechargeState.PENDING,
                          "bg-green-500":
                            recharge.is_succeeded ===
                            RechargeState.SUCCESSFULLY,
                          "bg-red-500":
                            recharge.is_succeeded === RechargeState.CANCELLED,
                          "bg-orange-500":
                            recharge.is_succeeded === RechargeState.TIMEOUT,
                        })}
                      >
                        {RechargeStateName[recharge.is_succeeded]}
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {!recharges.length && (
            <div className="flex justify-center">Không có dữ liệu</div>
          )}
        </div>
      </div>
      {totalPages > 0 && (
        <div className="flex justify-center mt-3">
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </div>
  );
}

export default PaymentHistory;
