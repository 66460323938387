import {
  PortalDialogProps,
  openPortalDialog,
} from "@app/services/modal.service";
import Modal from "../modal/modal.component";
import { ImageItem } from "@app/types";
import ConfirmModal from "../confirm-modal";

function WanModal({ portalDialogRef, portalData }: PortalDialogProps) {
  const image: ImageItem = portalData;

  const handleClickWan = (wan: string) => {
    const confirmDialog = openPortalDialog(ConfirmModal, {
      message: `Xác nhận chọn ${wan}`,
    });

    confirmDialog.afterClosed().subscribe((data) => {
      if (data?.isAccept) {
        portalDialogRef.close({ wan });
      }
    });
  };

  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren>
      <div className="w-[500px] p-4">
        <h1 className="text-center m-4 font-bold text-xl">
          Chọn đường truyền Internet
        </h1>
        <div className="flex gap-4 flex-wrap justify-center">
          {image?.ssh?.router.split(",").map((wan) => {
            return (
              <div
                key={wan}
                className="w-[30%] text-center cursor-pointer bg-[#42A5F5] hover:bg-[#0f63a7] text-white py-1 px-2 rounded"
                onClick={() => handleClickWan(wan)}
              >
                {wan}
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
}

export default WanModal;
