import HttpService from "@core/services/http/http.service";
import {
  Account,
  LoginResponse,
  SignUpRequest,
  SignUpResponse,
} from "@app/types";

class _AuthService {
  public login(username: string, password: string) {
    return HttpService.post<LoginResponse>("/auth/login", {
      body: {
        username,
        password,
      },
    });
  }

  public signUp(signUpRequest: SignUpRequest) {
    return HttpService.post<SignUpResponse>("/auth/register", {
      body: { ...signUpRequest },
    });
  }

  public getOtp() {
    return HttpService.get<Account>("/auth/otp");
  }
}

const AuthService = new _AuthService();

export default AuthService;
