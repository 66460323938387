import RentalConfigModal from "@app/components/rental-config-modal";
import { addToast } from "@app/components/toast/toast.service";
import { PathnameUrl } from "@app/constants";
import ComputerConfigService from "@app/services/http/computer.config.service";
import OrderVpsService from "@app/services/http/order.vps.service";
import { openPortalDialog } from "@app/services/modal.service";
import { ComputerConfig, CreateOrderVps } from "@app/types";
import { formatMoneyVN } from "@app/utils/util";
import { Images } from "@assets/images";
import useObservable from "@core/hooks/use-observable.hook";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function VpsRental() {
  const [configs, setConfigs] = useState<ComputerConfig[]>([]);
  const { subscribeOnce } = useObservable();
  const [selectingConfig, setSelectionConfig] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    subscribeOnce(ComputerConfigService.getConfigs(), (configs) => {
      if (configs.length) {
        setConfigs(configs);
      } else {
        setConfigs([]);
      }
    });
  }, []);

  const rentalConfig = (config: ComputerConfig) => {
    const obs = openPortalDialog(RentalConfigModal, {
      price: config.monthly_rental_price,
    });

    obs.afterClosed().subscribe((res) => {
      if (res?.data) {
        const body: CreateOrderVps = {
          rental_period: res.data.rental_period,
          config_id: config._id,
          os: res.data.os,
        };

        subscribeOnce(OrderVpsService.createOrderVps(body), () => {
          addToast({ text: "Thuê VPS Thành Công" });
          navigate(PathnameUrl.ORDER_VPS_MANAGEMENT);
        });
      }
    });
  };

  return (
    <div className="flex flex-wrap justify-start pb-4 ml-[-16px] p-4">
      {!!configs.length &&
        configs?.map((config) => (
          <div
            onClick={() => setSelectionConfig(config._id)}
            key={config._id}
            className={clsx(
              `${
                selectingConfig == config._id ? "border-vps" : "boder-vps2"
              } w-[calc(90%-16px)] sm:w-[calc(33%-16px)] lg:w-[calc(25%-16px)] border-4 rounded-lg mt-4 ml-4 overflow-hidden`
            )}
          >
            <div
              className={clsx(
                `${
                  selectingConfig == config._id ? "bg-vps" : "bg-vps2"
                } text-white font-bold text-xl text-center p-2`
              )}
            >
              {config.config_name}
            </div>
            <div className="p-3">
              <div className="my-1">
                <span className="text-3xl text-[#2f1c6a] font-bold">
                  {formatMoneyVN(config.monthly_rental_price)}
                </span>{" "}
                VNĐ/tháng
              </div>
              <div
                className="border-[#673de6] border-2 text-center p-2 font-bold rounded-lg text-[#673de6] cursor-pointer hover:text-white hover:bg-[#673de6]"
                onClick={() => rentalConfig(config)}
              >
                Chọn gói
              </div>
              <div className="h-[1px] bg-slate-400 my-6"></div>
              <div className="flex items-center gap-2">
                <img className="w-4" src={Images.Check.default} alt="" />
                <div>
                  <span className="font-bold">CPU: </span>
                  <span>{config.cpu}</span>
                </div>
              </div>
              <div className="flex items-center gap-2 mt-2">
                <img className="w-4" src={Images.Check.default} alt="" />
                <div>
                  <span className="font-bold">RAM: </span>
                  <span>{config.ram}</span>
                </div>
              </div>
              <div className="flex items-center gap-2 mt-2">
                <img className="w-4" src={Images.Check.default} alt="" />
                <div>
                  <span className="font-bold">SSD: </span>
                  <span>{config.ssd}</span>
                </div>
              </div>
              <div className="flex items-center gap-2 mt-2">
                <img className="w-4" src={Images.Check.default} alt="" />
                <div>
                  <span className="font-bold">GPU: </span>
                  <span>{config.gpu}</span>
                </div>
              </div>
              <div className="flex items-center gap-2 mt-2">
                <img className="w-4" src={Images.Check.default} alt="" />
                <div>
                  <span className="font-bold">TỐC ĐỘ MẠNG: </span>
                  <span>{config.network_speed}</span>
                </div>
              </div>
              <div className="flex items-center gap-2 mt-2">
                <img className="w-4" src={Images.Check.default} alt="" />
                <div>
                  <span className="font-bold">IPV4: </span>
                  <span>{config.ipv4}</span>
                </div>
              </div>
              <div className="flex items-center gap-2 mt-2">
                <img className="w-4" src={Images.Check.default} alt="" />
                <div>
                  <span className="font-bold">HỆ ĐIỀU HÀNH: </span>
                  <span>{config.os}</span>
                </div>
              </div>
              <div className="flex items-center gap-2 mt-2">
                <img className="w-4" src={Images.Check.default} alt="" />
                <div>
                  <span className="font-bold">TRUNG TÂM DỮ LIỆU: </span>
                  <span>{config.data_center}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default VpsRental;
