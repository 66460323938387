import { PortalDialogProps } from "@app/services/modal.service";
import Modal from "../modal/modal.component";
import { GlobalState } from "@app/store";
import { useSelector } from "react-redux";
import useObservable from "@core/hooks/use-observable.hook";
import AuthService from "@app/services/http/auth.service";
import { addToast } from "../toast/toast.service";
import ReactInputVerificationCode from "react-input-verification-code";
import { useEffect } from "react";

function VerificationCodeModal({ portalDialogRef }: PortalDialogProps) {
  const { myInfo } = useSelector(selectMyInfo);

  const { subscribeOnce } = useObservable();

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--ReactInputVerificationCode-itemWidth",
      "3.5rem"
    );
    document.documentElement.style.setProperty(
      "--ReactInputVerificationCode-itemHeight",
      "4rem"
    );
  }, []);

  const handleResendEmail = () => {
    subscribeOnce(AuthService.getOtp(), () => {
      addToast({ text: "Gửi lại mã OTP thành công" });
    });
  };

  const handleOnCodeCompleted = (code: string) => {
    if (!isNaN(Number(code))) {
      portalDialogRef.close({ code });
    }
  };

  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren>
      <div className="w-[450px] px-8 py-5">
        <div className="font-bold text-xl border-b border-solid border-[#888888] pb-4">
          Xác thực tạo chữ ký
        </div>
        <div className="mt-4">
          Bạn vui lòng nhập mã OTP đã được gửi về email:{" "}
          <span>{myInfo.email}</span>
        </div>
        <div className="mt-4 flex justify-center">
          <ReactInputVerificationCode
            autoFocus
            length={6}
            onCompleted={handleOnCodeCompleted}
          />
        </div>
        <div className="mt-4 text-gray-500 underline">
          <span className="cursor-pointer" onClick={handleResendEmail}>
            Gửi lại email
          </span>
        </div>
      </div>
    </Modal>
  );
}

const selectMyInfo = (state: GlobalState) => state.myInfo;

export default VerificationCodeModal;
