import { VPS_OS } from "@app/constants/vps.const";
import * as Yup from "yup";

export const createOrderVpsValidationSchema = Yup.object().shape({
  rental_period: Yup.number()
    .typeError("Số tháng phải là một số") // Lỗi khi không phải số
    .integer("Số tháng phải là một số nguyên")
    .min(1, "Số tháng cần nhập từ 1 trở lên")
    .required("Số tháng thuê không được để trống"),
  os: Yup.string()
    .oneOf(Object.values(VPS_OS), "Hệ điều hành không hợp lệ")
    .required("Cần chọn hệ điều hành"),
});

export const extendOrderVpsValidationSchema = Yup.object().shape({
  rental_period: Yup.number()
    .min(1, "Số tháng cần nhập từ 1 trở lên")
    .required("Số tháng thuê không được để trống"),
});
