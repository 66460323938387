import { PortalDialogProps } from "@app/services/modal.service";
import Modal from "../modal/modal.component";
import Button from "../button";
import Input from "../input";
import { Form, Formik } from "formik";
import { VPS_OS } from "@app/constants";
import { FormControl } from "../form-control";
import { CustomSelect } from "../select/custom-select";
import TriangleDownArrow from "../select/triangle-down-arrow";
import { NormalSelectOptions } from "../select/select.type";
import { createOrderVpsValidationSchema } from "@app/validations";
import { useState } from "react";
import { formatMoneyVN } from "@app/utils/util";

function RentalConfigModal({ portalDialogRef, portalData }: PortalDialogProps) {
  const [month, setMonth] = useState(0);
  const os: NormalSelectOptions = [
    {
      label: "Ubuntu",
      value: VPS_OS.UBUNTU,
    },
    {
      label: "Windows",
      value: VPS_OS.WINDOW_SERVER,
    },
  ];

  const handleSubmit = (value: any) => {
    portalDialogRef.close({ data: value });
  };

  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren>
      <div className="w-[350px] h-auto p-5">
        <div className="text-lg pr-5">Chọn thông tin thuê VPS</div>
        <div className="my-3">
          <Formik
            initialValues={{ rental_period: 0, os: "" }}
            validationSchema={createOrderVpsValidationSchema}
            onSubmit={handleSubmit}
            validateOnChange
            validateOnBlur
          >
            <Form>
              <div className="flex justify-between gap-5">
                <div className="flex-1">
                  <div className="mt-3 flex-1">
                    <div className="text-sm">Nhập số tháng</div>
                    <FormControl name="rental_period">
                      <Input
                        className="mt-1 !max-w-none text-sm"
                        errorClassName="text-sm"
                        onChange={(e) => setMonth(+e.target.value)}
                      />
                    </FormControl>
                    <div>
                      {!!month && (
                        <div className="text-[14px]">
                          Tổng tiền: {formatMoneyVN(month * +portalData?.price)}{" "}
                          VNĐ
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className="text-sm">Hệ điều hành</div>
                    <FormControl name="os">
                      <CustomSelect
                        options={os}
                        width="100%"
                        placeholder="Chọn hệ điều hành"
                        className="!h-9 !border-[#3A466480] !rounded-lg"
                        optionContainerClassName="top-[34px] w-full"
                        optionClassName="!min-h-[35px] text-sm text-black whitespace-nowrap px-2"
                        labelSelectedClassName="text-black text-sm"
                        errorClassName="!text-[#D60000B2] text-sm"
                        arrow={<TriangleDownArrow />}
                        hasBgColorHover
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="text-red-500 text-[14px] mt-2">
                Lưu ý: Không hoàn tiền khi đã thuê máy, vui lòng nhập đúng thời
                gian sử dụng
              </div>
              <Button
                type="submit"
                label="Bắt đầu thuê"
                containerClassName="mt-5 mx-auto"
                width="fit-content"
                className="px-6 text-sm"
                labelClassName="font-bold"
              />
            </Form>
          </Formik>
        </div>
      </div>
    </Modal>
  );
}

export default RentalConfigModal;
