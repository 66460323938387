import { Account, VerifyAccountRequest } from "@app/types";
import HttpService from "@core/services/http/http.service";
import { RequestContentType } from "@core/services/http/http.type";

class _AccountService {
  public getMyInfo() {
    return HttpService.get<Account>("/accounts/me");
  }

  public verifyAccount(verifyAccountRequest: VerifyAccountRequest) {
    return HttpService.patch<Account>("/accounts", {
      requestContentType: RequestContentType.MULTIPART,
      body: { ...verifyAccountRequest },
    });
  }
}

const AccountService = new _AccountService();

export default AccountService;
