import { ServiceData } from "@app/constants";
import { GlobalState } from "@app/store";
import { Images } from "@assets/images";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import PackageService from "@app/services/http/package.service";
import { Package } from "@app/types";
import useObservable from "@core/hooks/use-observable.hook";
import { formatMoneyVN } from "@app/utils/util";

function Home() {
  const [packages, setPackages] = useState<Package[]>([]);
  const navigate = useNavigate();

  const { myInfo } = useSelector(selectMyInfo);

  const { subscribeUntilDestroy } = useObservable();

  useEffect(() => {
    subscribeUntilDestroy(PackageService.getAllPackages(), (packages) => {
      if (packages.length) {
        setPackages(packages);
      } else {
        setPackages([]);
      }
    });
  }, []);

  return (
    <div>
      <div className="sticky h-16 top-0 z-20 bg-white w-full border-b border-solid border-gray-300 shadow-[0_2px_12px_0_rgba(0,0,0,10%)]">
        <div className="flex items-center h-full w-[98%] md:w-[70%] mx-auto justify-between">
          <div className="h-full flex items-center">
            <img
              src={Images.LogoPcRender.default}
              className="w-48 my-auto cursor-pointer"
              alt=""
            />
          </div>
          <div>
            {myInfo._id ? (
              <div
                className="flex justify-end md:items-center cursor-pointer hover:text-blue-500"
                onClick={() => navigate("/")}
              >
                Trang quản lý
              </div>
            ) : (
              <div className="flex justify-end items-center gap-2">
                <div
                  className="cursor-pointer hover:text-blue-500"
                  onClick={() => navigate("/login")}
                >
                  Đăng nhập
                </div>
                <div>|</div>
                <div
                  className="cursor-pointer hover:text-blue-500"
                  onClick={() => navigate("/register")}
                >
                  Đăng ký
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-[70%] mx-auto mt-7">
        <div className="md:grid grid-cols-2">
          <div className="flex flex-col my-auto">
            <h2 className="text-secondary text-4xl font-bold">PCRender</h2>
            <p className="font-roboto">
              <br />
              Cho thuê máy tính vật lý sử dụng CPU dual Xeon lên tới 44 core 88
              luồng kết hợp VGA 3060 12GB vô cùng mạnh mẽ chỉ từ 15.000 vnđ sử
              dụng ngay.
              <br />
              <br />
              Website cho thuê tự động 24/7.
              <br />
              <br />
              Đăng ký , đăng nhập để sử dụng tính năng !
            </p>
          </div>
          <div className="">
            <img
              className="w-[100%]"
              src="https://res.cloudinary.com/djqe3fdeq/image/upload/v1691246671/vps_y5nlku.webp"
            />
          </div>
        </div>
      </div>
      <div className="bg-[#f8f8f8]">
        <div className="w-[70%] mx-auto mt-12 text-center box-content">
          <h2 className="text-secondary h-10 leading-10 py-16 lg:py-8 md:py-8 text-4xl font-bold">
            Dịch Vụ Tiêu Biểu Tại PcRender
          </h2>
          <p className="h-10 lg:leading-4 py-16 lg:py-8">
            Dịch vụ Cloud VPS, hiệu suất cao, đồng hành cùng sự phát triển của
            bạn.
          </p>
          <div className="flex flex-col lg:grid lg:grid-cols-3">
            {ServiceData.map((service, index) => {
              return (
                <div
                  key={index}
                  className="bg-white rounded-md shadow-md shadow-primary relative overflow-hidden hover:shadow-lg hover:shadow-primary lg:mx-4 my-4 lg:my-12 hover:scale-125 transition ease-out duration-500 hover:z-10"
                >
                  <img src={service.img} className="mx-auto h-28 m-2" />
                  <div className="p-4 ">
                    <h4 className="text-secondary text-2xl font-bold">
                      {service.title}
                    </h4>
                    <p className="text-primary">{service.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div id="packages_list">
        <h1 className="text-center text-4xl my-8 font-bold">
          Bảng Giá Dịch Vụ Thuê Máy Chủ Vật Lý
        </h1>
        <div className="px-2 overflow-x-auto mt-2">
          <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead className="text-white">
                <tr className="border-b-2 border-gray-200 bg-[#409EFF] text-sm text-center">
                  <th className="p-3 text-left w-72">Tên Sever</th>
                  <th className="p-3 text-left w-56">CPU</th>
                  <th className="p-3 text-left w-56">GPU</th>
                  <th className="text-left w-36">RAM</th>
                  <th className="text-left w-36">SSD</th>
                  <th className="p-3 text-left w-52">OS</th>
                  <th className="p-3 text-left w-52">Giá theo giờ (VNĐ)</th>
                  <th className="p-3 text-left w-52">Giá theo ngày (VNĐ)</th>
                  <th className="p-3 text-left w-52">Giá theo tháng (VNĐ)</th>
                  <th className="p-3 text-left w-52">Thuê Ngay</th>
                </tr>
              </thead>
              <tbody>
                {!!packages.length &&
                  packages.map((item, index) => (
                    <tr
                      key={item._id}
                      className={
                        "border-b border-gray-200 text-sm hover:bg-[#51b83c2c] " +
                        (index % 2 === 1 ? "bg-[#7576752c]" : "")
                      }
                    >
                      <td className="p-3">{item.name}</td>
                      <td className="p-3">{item.cpu}</td>
                      <td className="p-3">{item.gpu}</td>
                      <td className="">{item.ram}</td>
                      <td className="">{item.ssd}</td>
                      <td className="p-3">{item.os}</td>
                      <td className="p-3">
                        {item.price_per_hour &&
                          formatMoneyVN(item.price_per_hour)}
                      </td>
                      <td className="p-3">
                        {item.price_per_day &&
                          formatMoneyVN(item.price_per_day)}
                      </td>
                      <td className="p-3">
                        {item.price_per_month &&
                          formatMoneyVN(item.price_per_month)}
                      </td>
                      <td className="p-3 text-blue-600">
                        <a href="/register">Thuê Ngay</a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {!packages.length && (
              <div className="flex justify-center">Không có dữ liệu</div>
            )}
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="w-[70%] mx-auto">
          <h2 className="my-8 pt-4 text-4xl font-bold">
            Cloud VPS Của PC Render Có Gì Tốt?
          </h2>
          <div className="md:grid md:grid-cols-2">
            <div className="flex col-span-1 justify-between flex-wrap">
              <div className="md:w-[45%]">
                <img
                  className="w-[40%] max-sm:mt-4 max-sm:mx-auto md:w-[25%] hover:scale-125 transition ease-out duration-500"
                  src="https://res.cloudinary.com/djqe3fdeq/image/upload/v1691250744/hardware_xgmfcj.svg"
                  alt=""
                />
                <h4 className="md:my-4 my-2 hover:scale-125 transition ease-out duration-500 text-2xl font-bold">
                  PHẦN CỨNG MẠNH MẼ
                </h4>
                <p className="text-justify">
                  Khi sử dụng dịch vụ tại PCRender, bạn có thể hoàn toàn yên
                  tâm. Sử dụng CPU dual Xeon mới nhất ,kết hợp cùng VGA 1080
                  ,1080ti ,3060… vô cùng mạnh mẽ
                </p>
              </div>
              <div className="md:w-[45%]">
                <img
                  className="w-[40%] max-sm:mt-8 max-sm:mx-auto  md:w-[25%] hover:scale-125 transition ease-out duration-500"
                  src="https://res.cloudinary.com/djqe3fdeq/image/upload/v1691250744/scale_na2cda.svg"
                  alt=""
                />
                <h4 className="md:my-4 my-2 hover:scale-125 transition ease-out duration-500 text-2xl font-bold">
                  NHIỀU LỰA CHỌN CẤU HÌNH
                </h4>
                <p className="text-justify">
                  PCRender cung cấp nhiều cấu hình tự chọn ,từ VGA 1050ti tới
                  VGA 3060 ,4090…vô cùng mạnh mẽ
                </p>
              </div>
              <div className="md:w-[45%] mt-2 pb-4">
                <img
                  className="w-[40%] max-sm:mt-4 max-sm:mx-auto md:w-[25%] hover:scale-125 transition ease-out duration-500"
                  src="https://res.cloudinary.com/djqe3fdeq/image/upload/v1691250744/ttmb_l85avc.svg"
                  alt=""
                />
                <h4 className="md:my-4 my-2 hover:scale-125 transition ease-out duration-500 text-2xl font-bold">
                  THÔNG TIN MINH BẠCH, CHÍNH SÁCH RÕ RÀNG
                </h4>
                <p className="text-justify">
                  Tất cả các thông tin liên quan đến{" "}
                  <a
                    className="text-sm text-[#42A5F5] font-bold hover:underline"
                    href="https://api.pcrender.com/view/term-of-service"
                    target="_blank"
                    rel="noreferrer"
                  >
                    điều khoản
                  </a>{" "}
                  và chính sách của Pc Render Cloud có nội dung thể hiện đầy đủ,
                  chi tiết và được công khai. Khách hàng có thể an tâm tuyệt
                  đối.
                </p>
              </div>
              <div className="md:w-[45%] mt-2">
                <img
                  className="w-[40%] max-sm:mt-4 max-sm:mx-auto md:w-[25%] hover:scale-125 transition ease-out duration-500"
                  src="https://res.cloudinary.com/djqe3fdeq/image/upload/v1691250744/support247_bm4gfr.svg"
                  alt=""
                />
                <h4 className="md:my-4 my-2 hover:scale-125 transition ease-out duration-500 text-2xl font-bold">
                  THANH TOÁN LINH HOẠT
                </h4>
                <p className="text-justify">
                  Bạn có thể thanh toán theo giờ ,ngày ,tháng ,năm ,tùy vào nhu
                  cầu sử dụng của bạn ,hệ thống hoạt động 24/7 nên bạn có thể
                  thuê máy bất kỳ thời gian nào
                </p>
              </div>
            </div>
            <div className="flex items-center col-span-1">
              <img
                src={
                  "https://res.cloudinary.com/djqe3fdeq/image/upload/v1691250745/vps2_yrrqs5.png"
                }
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#f8f8f8]">
        <div className="flex flex-col text-center mt-4 w-[70%] mx-auto py-8">
          <h2 className="mb-4 text-4xl font-bold">Hệ Điều Hành Hỗ Trợ</h2>
          <p>
            Chúng tôi hỗ trợ đầy đủ loại hệ điều Windows và Linux cho dịch vụ
            Cloud VPS. <br /> Cài đặt nhanh chóng, thuận tiện chỉ với một click
            chuột.
          </p>
          <div className="md:flex md:justify-around mt-8">
            <img
              className="md:w-[30%] transition-all duration-800 hover:translate-y-[-0.3rem]"
              src="https://res.cloudinary.com/djqe3fdeq/image/upload/v1691548655/windows_server_2012_logo-300x37-1_kq8ccv.png"
              alt=""
            />
            <img
              className="md:w-[30%] transition-all duration-800 hover:translate-y-[-0.3rem] max-sm:mt-4"
              src="https://res.cloudinary.com/djqe3fdeq/image/upload/v1691548655/windows_server_2016_logo-300x34-1_kqylxl.png"
              alt=""
            />
            <img
              className="md:w-[30%] transition-all duration-800 hover:translate-y-[-0.3rem] max-sm:mt-4"
              src="https://res.cloudinary.com/djqe3fdeq/image/upload/v1691548655/windows_server_2019_logo-300x34-1_salykc.png"
              alt=""
            />
          </div>
          <div className="md:flex md:justify-around md:mt-8 max-sm:mt-4">
            <div className="md:w-[30%]">
              <img
                className="transition-all duration-800 hover:translate-y-[-0.3rem]"
                src="https://res.cloudinary.com/djqe3fdeq/image/upload/v1691548656/windows_server_2022_logo-300x34-1_nfeasf.png"
                alt=""
              />
            </div>
            <div className="md:w-[30%]">
              <img
                className="transition-all duration-800 hover:translate-y-[-0.3rem] max-sm:mt-4"
                src="https://res.cloudinary.com/djqe3fdeq/image/upload/v1691548656/windows_10_logo_h2ipe1.png"
                alt=""
              />
            </div>
            <div className="md:w-[30%]">
              <img
                className="transition-all duration-800 hover:translate-y-[-0.3rem] max-sm:mt-4"
                src="https://res.cloudinary.com/djqe3fdeq/image/upload/v1691548655/ubuntu_logo-q9xu99m7bvxoplu66fhx9kkteupprbg4o21it0gx6o_t5odak.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="relative">
        <img
          src="https://res.cloudinary.com/djqe3fdeq/image/upload/v1691549755/vpshosting_zstnzw.png"
          alt=""
          className="w-full"
        />
        <div className="text-white absolute top-1 right-1 text-start w-[40%] md:top-16 md:right-24 md:w-[35%]">
          <h1 className="md:my-8 md:text-5xl text-sm font-bold">
            Cùng nhau, Trực tuyến.
          </h1>
          <p className="md:text-2xl text-[8px]">
            Chúng tôi ở đây giúp các bạn có thể làm việc online một cách hiểu
            quả nhất.
          </p>
        </div>
      </div>
      <div>
        <div className="md:grid md:grid-cols-4 w-[70%] mx-auto mt-8 gap-2">
          <div className="flex flex-col col-span-1">
            <h2 className="text-secondary my-2 text-4xl font-bold">
              Pc Render
            </h2>
            <div className="flex items-center my-2">
              <i className="fa fa-building text-blue-500"></i>
              <p className="pl-2">CÔNG TY TNHH CÔNG NGHỆ PCRENDER</p>
            </div>
            <div className="flex items-center my-2">
              <i className="fa fa-search-location text-cyan-500"></i>
              <p className="pl-2">Số 6 Tự Do , Tân Thành , Tân Phú , HCM</p>
            </div>
            <div className="flex items-center my-2">
              <i className="fa fa-envelope text-pink-500"></i>
              <p className="pl-2">Support@pcrender.com</p>
            </div>
            <div className="flex items-center my-2">
              <i className="fa fa-phone text-green-500"></i>
              <p className="pl-2">083.321.8888</p>
            </div>
            <h4 className="text-2xl font-bold">Theo Dõi Chúng Tôi</h4>
            <div className="flex">
              <div className="border-2 border-cyan-900 rounded p-2 m-2 cursor-pointer hover:scale-125 transition ease-out duration-500">
                <a
                  href="https://www.facebook.com/pcrenderrdp?mibextid=LQQJ4d"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-facebook text-blue-500"></i>
                </a>
              </div>
              {/* <div className="border-2 border-cyan-900 rounded p-2 m-2 cursor-pointer hover:scale-125 transition ease-out duration-500">
                <span className="text-xs">Zalo</span>
              </div>
              <div className="border-2 border-cyan-900 rounded p-2 m-2 cursor-pointer hover:scale-125 transition ease-out duration-500">
                <i className="fab fa-instagram text-[#EF4B5E]"></i>
              </div> */}
              <div className="border-2 border-cyan-900 rounded p-2 m-2 cursor-pointer hover:scale-125 transition ease-out duration-500">
                <a
                  href="https://www.youtube.com/@pcrender"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-youtube text-red-500"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="flex flex-col col-span-1">
            <h2 className="my-2 text-4xl font-bold">Thông tin</h2>
            <p className="my-2">
              <a
                className="text-sm text-[#606266] font-bold hover:underline"
                href="https://api.pcrender.com/view/introduce"
                target="_blank"
                rel="noreferrer"
              >
                Giới Thiệu
              </a>
            </p>
            <p className="my-2">
              <a
                className="text-sm text-[#606266] font-bold hover:underline"
                href="https://api.pcrender.com/view/contact"
                target="_blank"
                rel="noreferrer"
              >
                Liên Hệ
              </a>
            </p>
            <p className="my-2">
              <a
                className="text-sm text-[#606266] font-bold hover:underline"
                href="https://api.pcrender.com/view/term-of-service"
                target="_blank"
                rel="noreferrer"
              >
                Điều Khoản
              </a>
            </p>
          </div>
          {/* <div className="flex flex-col col-span-1">
            <h2 className="my-2 text-4xl font-bold">Dịch vụ</h2>
            <p className="my-2">VPS US - Mỹ</p>
            <p className="my-2">VPS Singapore</p>
            <p className="my-2">VPS Australia</p>
            <p className="my-2">VPS Châu Âu</p>
            <p className="my-2">VPS Canada</p>
            <p className="my-2">VPS Việt Nam</p>
            <p className="my-2">VPS Forex</p>
            <p className="my-2">VPS Windows</p>
            <p className="my-2">Máy Chủ Vật Lý</p>
          </div>
          <div className="flex flex-col col-span-1">
            <h2 className="my-2 text-4xl font-bold">Hữu ích</h2>
            <p className="my-2">Blog</p>
            <p className="my-2">Thông báo</p>
            <p className="my-2">Hướng dẫn sử dụng</p>
            <p className="my-2">Affiliate</p>
          </div> */}
        </div>
      </div>
    </div>
  );
}

const selectMyInfo = (state: GlobalState) => state.myInfo;

export default Home;
