import BootImageModal from "@app/components/boot-image-modal";
import Button from "@app/components/button";
import ComputerCard from "@app/components/computer-card";
import ConfirmModal from "@app/components/confirm-modal";
import CreateImageModal from "@app/components/create-image-modal";
import { addToast } from "@app/components/toast/toast.service";
import {
  BootStatusCode,
  DEFAULT_PAGE,
  DEFAULT_TOTAL_PAGES,
  SystemMessage,
} from "@app/constants";
import ImageService from "@app/services/http/image.service";
import OrderService from "@app/services/http/order.service";
import { openPortalDialog } from "@app/services/modal.service";
import SocketService from "@app/services/socket.service";
import { CreateOrderRequest, ImageItem } from "@app/types";
import { downloadFile } from "@app/utils/util";
import useForceUpdate from "@core/hooks/use-force-update.hook";
import useObservable from "@core/hooks/use-observable.hook";
import { useState, useEffect } from "react";
import { Subscription } from "rxjs";

function GpuHub() {
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [totalPages, setTotalPages] = useState(DEFAULT_TOTAL_PAGES);
  const [images, setImages] = useState<ImageItem[]>([]);

  const [update, forceUpdate] = useForceUpdate();

  const { subscribeOnce, subscribeUntilDestroy } = useObservable();

  const subscription = new Subscription();

  useEffect(() => {
    const sub = SocketService.onBootResult().subscribe((data) => {
      if (
        [
          BootStatusCode.CANNOT_CREATE_RDP_FILE,
          BootStatusCode.IMAGE_IS_OUT,
        ].includes(data.statusCode)
      ) {
        addToast({ text: data.message, status: "inValid" });
      }

      if (data.statusCode === BootStatusCode.SUCCESS) {
        addToast({ text: data.message });
      }

      forceUpdate();
    });

    subscription.add(sub);
  }, [subscription]);

  useEffect(() => () => subscription.unsubscribe(), [subscription]);

  useEffect(() => {
    if (page > DEFAULT_PAGE) {
      subscribeUntilDestroy(ImageService.getImage(page), (res) => {
        if (res.data.length) {
          const newImages = images.length ? [...images, ...res.data] : res.data;

          setImages(newImages);
          setTotalPages(res.total_page);
        }
      });
    }
  }, [page]);

  useEffect(() => {
    subscribeUntilDestroy(ImageService.getImage(DEFAULT_PAGE), (res) => {
      if (res.data.length) {
        setImages(res.data);
        setTotalPages(res.total_page);
      } else {
        setImages([]);
      }

      setPage(DEFAULT_PAGE);
    });
  }, [update]);

  const handleCreateImage = () => {
    const createImageObs = openPortalDialog(CreateImageModal);

    createImageObs.afterClosed().subscribe((data) => {
      if (data?.image) {
        addToast({ text: SystemMessage.CREATE_SUCCESSFULLY });
        forceUpdate();
      }
    });
  };

  const handleViewMore = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handleDeleteImage = (imageId: string) => {
    const deleteImageObs = openPortalDialog(ConfirmModal, {
      message: "Bạn có chắc chắn xóa PC này không?",
    });

    deleteImageObs.afterClosed().subscribe((data) => {
      if (data?.isAccept) {
        subscribeOnce(ImageService.deleteImage(imageId), () => {
          addToast({ text: SystemMessage.DELETE_SUCCESSFULLY });
          forceUpdate();
        });
      }
    });
  };

  const onBootImageClick = (image: ImageItem) => {
    const bootImageModalObs = openPortalDialog(BootImageModal, { image });

    bootImageModalObs.afterClosed().subscribe((data) => {
      if (data?.rentalPeriod && data?.unit) {
        const createOrderRequest: CreateOrderRequest = {
          image: image._id,
          rental_period: data.rentalPeriod,
          unit: data.unit,
        };

        subscribeOnce(OrderService.createOrder(createOrderRequest), (data) => {
          const image = images.find((image) => image._id === data._id);
          const imageIndex = images.findIndex(
            (image) => image._id === data._id
          );

          if (image && imageIndex !== -1) {
            const newImages = [...images];

            image.is_booting = data.is_booting;
            image.is_installed = data.is_installed;

            newImages.splice(imageIndex, 1, image);

            setImages(newImages);
          }

          addToast({ text: `Image: ${data.name} đang được boot` });
        });
      }
    });
  };

  const onConnectImageClick = (imageId: string) => {
    subscribeOnce(OrderService.getRDPFile(imageId), (data) => {
      const contentDisposition = data.responseHeaders["content-disposition"];

      const fileNameOriginal = contentDisposition?.split("filename=")?.[1];

      if (fileNameOriginal) {
        const fileName = fileNameOriginal.substring(
          1,
          fileNameOriginal.length - 2
        );

        if (fileName) {
          downloadFile(data.response, "application/rdp", fileName);
        }
      }
    });
  };

  //start chuyển thành reset
  const handleStartClick = (ipAddress: string) => {
    // subscribeOnce(ImageService.wol(imageId), () => {
    //   addToast({
    //     text: "Bật máy thành công, vui lòng đợi 60s rồi ấn connect để truy cập",
    //   });
    // });
    subscribeOnce(OrderService.reset(ipAddress), (data) => {
      if (data.message) {
        addToast({ text: data.message });
        forceUpdate();
      }
    });
  };

  const onReConnect = (imageId: string) => {
    subscribeOnce(ImageService.reConnect(imageId), (res) => {
      if (res.statusCode === 200) {
        addToast({
          text: "Kết nối lại thành công! Pass mới xem ở phần 'quản lý log'",
        });
      } else {
        addToast({
          text: "Lỗi tạo kết nối lại, vui lòng thử lại sau 2 phút, không được thì liên hệ admin",
        });
      }
    });
  };

  const handleExtendClick = (image: ImageItem) => {
    const bootImageModalObs = openPortalDialog(BootImageModal, {
      image,
      is_extends: true,
    });

    bootImageModalObs.afterClosed().subscribe((data) => {
      if (data?.rentalPeriod && data?.unit) {
        const createOrderRequest: CreateOrderRequest = {
          image: image._id,
          rental_period: data.rentalPeriod,
          unit: data.unit,
        };

        subscribeOnce(OrderService.extendOrder(createOrderRequest), () => {
          addToast({ text: "Gia hạn thành công" });
        });
      } 
    });
 
  };

  const onReload = () => {
    forceUpdate();
  };

  return (
    <div className="w-full p-4 bg-white">
      <div className="w-full">
        <Button
          label="Tạo PC"
          size="xs"
          theme="secondary"
          labelClassName="text-[13.6px]"
          width="fit-content"
          className="px-7 rounded-3xl"
          onClick={handleCreateImage}
        />
      </div>
      <div className="mt-4">
        <div className="p-4 bg-[#FAFAFA] h-[calc(100vh-160px)] max-sm:h-[calc(100vh-128px)] overflow-y-auto border-[2px] border-solid border-[#EEE]">
          {!!images.length &&
            images.map((image, index) => (
              <ComputerCard
                key={image._id}
                image={image}
                index={index}
                onDeleteImage={handleDeleteImage}
                onBootImage={onBootImageClick}
                onConnectImage={onConnectImageClick}
                onStartImage={handleStartClick}
                onExtendImage={handleExtendClick}
                onReConnect={onReConnect}
                onReload={onReload}
              />
            ))}
          <div className="mt-4 w-full flex justify-center">
            <Button
              label="Xem thêm"
              size="xs"
              width="fit-content"
              labelClassName="text-[13.6px]"
              className="px-4 rounded-xl"
              disabled={page === totalPages}
              onClick={() => handleViewMore()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GpuHub;
