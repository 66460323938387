export const ServiceData = [
  {
    img: "https://vmon.vn/images/vmon/icon-windows.svg",
    title: "PC RENDER",
    description:
      "PC vật lý chạy trên hệ điều hành Windows. Hỗ trợ VGA 4080 ,4090 …",
  },
  {
    img: "https://vmon.vn/images/elementor/thumbs/dedicatedsserver3-q9xu99m7bvyd9inbdpwvi3ktvguradtjs8mx8kav40.png",
    title: "Dịch vụ Thuê Máy Chủ Vật Lý",
    description:
      "Dịch vụ Thuê Server tại Pc Render Cloud có Phần cứng máy chủ mạnh mẽ, tốc độ mạng nhanh, hiệu năng cao, hỗ trợ 24/7.",
  },
  {
    img: "https://vmon.vn/images/vmon/vietnam-flag.svg",
    title: "PC GIẢ LẬP",
    description:
      "PC vật lý chạy trên hệ điều hành Windows. Hỗ trợ CPU Xeon 2680V4 , 2696V4… cùng VGA 1080 ,1080Ti, 3060 …",
  },
];
