import {
  ComputerConfig,  
} from "@app/types";
import HttpService from "@core/services/http/http.service";

class _ComputerConfigService {
  public getConfigs() {
    return HttpService.get<ComputerConfig[]>("/computer-configs");
  }
}

const ComputerConfigService = new _ComputerConfigService();

export default ComputerConfigService;
