import { PortalDialogProps } from "@app/services/modal.service";
import Modal from "../modal/modal.component";
import Button from "../button";
import Input from "../input";
import { Form, Formik } from "formik";
import { FormControl } from "../form-control";
import { extendOrderVpsValidationSchema } from "@app/validations";
import { useState } from "react";
import { formatMoneyVN } from "@app/utils/util";

function ExtendRentalConfigModal({
  portalDialogRef,
  portalData,
}: PortalDialogProps) {
  const [month, setMonth] = useState(0);
  const handleSubmit = (value: any) => {
    portalDialogRef.close({ rental_period: value.rental_period });
  };

  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren>
      <div className="w-[350px] h-auto p-5">
        <div className="text-lg pr-5">Gia hạn VPS</div>
        <div className="my-3">
          <Formik
            initialValues={{ rental_period: 0 }}
            validationSchema={extendOrderVpsValidationSchema}
            onSubmit={handleSubmit}
            validateOnChange
            validateOnBlur
          >
            <Form>
              <div className="flex justify-between gap-5">
                <div className="flex-1">
                  <div className="mt-3 flex-1">
                    <div className="text-sm">Nhập số tháng Gia Hạn</div>
                    <FormControl name="rental_period">
                      <Input
                        className="mt-1 !max-w-none text-sm"
                        errorClassName="text-sm"
                        type="number"
                        onChange={(e) => setMonth(+e.target.value)}
                      />
                    </FormControl>
                    {!!month && (
                      <div className="text-sm">
                        Tổng tiền: {formatMoneyVN(month * portalData.price)} VNĐ
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Button
                type="submit"
                label="Gia hạn"
                containerClassName="mt-5 mx-auto"
                width="fit-content"
                className="px-6 text-sm"
                labelClassName="font-bold"
              />
            </Form>
          </Formik>
        </div>
      </div>
    </Modal>
  );
}

export default ExtendRentalConfigModal;
