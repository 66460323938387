import Button from "@app/components/button";
import Checkbox from "@app/components/checkbox";
import { FormControl } from "@app/components/form-control";
import Input from "@app/components/input";
import SignUpModal from "@app/components/sign-up-modal";
import AuthService from "@app/services/http/auth.service";
import { openPortalDialog } from "@app/services/modal.service";
import { SignUpInitialValues, SignUpRequest } from "@app/types";
import { signUpValidationSchema } from "@app/validations";
import { Images } from "@assets/images";
import useObservable from "@core/hooks/use-observable.hook";
import { Form, Formik, FormikContextType } from "formik";
import { createRef } from "react";
import { useNavigate } from "react-router-dom";

function SignUp() {
  const formRef = createRef<FormikContextType<SignUpInitialValues>>();

  const navigate = useNavigate();

  const { subscribeOnce } = useObservable();

  const handleSubmit = (values: SignUpInitialValues) => {
    const signUpRequest: SignUpRequest = {
      username: values.username,
      fullName: values.fullName,
      password: values.password,
      email: values.email,
      phone: values.phone,
    };

    subscribeOnce(AuthService.signUp(signUpRequest), () => {
      openPortalDialog(SignUpModal);
    });
  };

  return (
    <div className="flex relative h-screen w-full bg-cover bg-no-repeat bg-center bg-[url(@assets/images/background.jpg)]">
      <div className="absolute h-screen w-full bg-black opacity-30"></div>
      <div className="flex max-[900px]:flex-col max-[900px]:gap-2 max-[900px]:justify-center w-full z-10">
        <div className="flex-1 max-sm:w-full max-[900px]:flex-none">
          <div className="w-10/12 h-full mx-auto flex-col flex items-center justify-center">
            <div className="w-">
              <img
                className="w-52 max-sm:w-36"
                src={Images.LogoPcRenderWhite.default}
                alt=""
              />
            </div>
            <div className="text-white italic mt-2 max-[900px]:hidden">
              &quot;Happy rendering&quot;
            </div>
            <div className="text-white mt-3 font-medium max-[900px]:hidden">
              Chào mừng bạn tới PCRENDER , chúng tôi rất vui mừng vì bạn đã ghé
              thăm website của chúng tôi !
            </div>
            <div className="text-white font-[14.4px] mt-1 max-[900px]:hidden">
              PCRENDER là dịch vụ cho thuê các máy chủ ,server ,vps ,pc cấu hình
              cao bao gồm GPU xử lý công việc của bạn 1 cách nhanh chóng ,thuận
              tiện .
              <br />
              Chỉ 1 click là bạn có thể tăng tốc máy tính của bạn bằng cách
              chuyển khối lượng công việc trên máy tính của bạn qua máy thuê của
              chúng tôi .
              <br />
              Dù bạn ở bất kỳ đâu ( hà nội ,hồ chí minh ,...) cũng có thể kết
              nối từ xa qua máy tính của chúng tôi qua trình điều khiển REMOTE
              DESKTOP !
              <br />
              Hân hạnh được phục vụ bạn !!!
            </div>
            <div className="text-white mt-3">
              <iframe
                className="w-[360px] h-[180px] max-[900px]:hidden"
                src="https://www.youtube.com/embed/c9W5mGHIDX0"
                title="Hướng dẫn sử dụng PCRENDER - website cho thuê máy tính cày view, giả lập, render ... tự động 24/7"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
            <div className="text-white min-[899px]:hidden">
              <a
                className="underline cursor-pointer"
                href="https://youtu.be/c9W5mGHIDX0"
                target="_blank"
                rel="noreferrer"
              >
                Hướng dẫn sử dụng
              </a>
            </div>
          </div>
        </div>
        <div className="max-sm:w-full flex-1 max-[900px]:flex-none">
          <div className="h-full mx-auto flex-col flex items-center justify-center">
            <div className="bg-[#FFFFFFDA] md:px-4 md:py-2 rounded-[10px]">
              <div className="p-4 max-sm:p-3">
                <div className="mb-[7px] mt-3 max-sm:mt-1">
                  <img
                    className="w-48 max-sm:w-20 mx-auto"
                    src={Images.LogoPcRender.default}
                    alt=""
                  />
                </div>
                <div className="text-[12.8px] text-[#6C757D] mt-1 text-center mb-7 max-sm:mb-1">
                  High-Performance GPU Computer Rental Service
                </div>
                <Formik
                  initialValues={{
                    username: "",
                    email: "",
                    fullName: "",
                    phone: "",
                    password: "",
                    confirmPassword: "",
                    isConfirmed: [],
                  }}
                  onSubmit={handleSubmit}
                  innerRef={formRef}
                  validationSchema={signUpValidationSchema}
                  validateOnChange
                  validateOnBlur
                >
                  <Form>
                    <FormControl name="username">
                      <Input
                        width="auto"
                        className="!w-[376px] max-sm:!w-[275px] !max-w-none mb-[22px] max-sm:mb-2"
                        inputClassName="!rounded !border-[#DCDFE6] h-[40px] leading-[40px] text-sm text-[#606266]"
                        placeholder="Tên tài khoản..."
                        inputElement={
                          <i className="fa fa-user text-[#c0c4cc]"></i>
                        }
                        errorClassName="text-[#F56C6C] text-xs"
                      />
                    </FormControl>
                    <FormControl name="fullName">
                      <Input
                        width="auto"
                        className="!w-[376px] max-sm:!w-[275px] !max-w-none mb-[22px] max-sm:mb-2"
                        inputClassName="!rounded !border-[#DCDFE6] h-[40px] leading-[40px] text-sm text-[#606266]"
                        placeholder="Họ tên..."
                        inputElement={
                          <i className="fa fa-id-card text-[#c0c4cc]"></i>
                        }
                        errorClassName="text-[#F56C6C] text-xs"
                      />
                    </FormControl>
                    <FormControl name="email">
                      <Input
                        width="auto"
                        className="!w-[376px] max-sm:!w-[275px] !max-w-none mb-[22px] max-sm:mb-2"
                        inputClassName="!rounded !border-[#DCDFE6] h-[40px] leading-[40px] text-sm text-[#606266]"
                        placeholder="Địa chỉ email..."
                        inputElement={
                          <i className="fa fa-envelope text-[#c0c4cc]"></i>
                        }
                        errorClassName="text-[#F56C6C] text-xs"
                      />
                    </FormControl>
                    <FormControl name="phone">
                      <Input
                        width="auto"
                        className="!w-[376px] max-sm:!w-[275px] !max-w-none mb-[22px] max-sm:mb-2"
                        inputClassName="!rounded !border-[#DCDFE6] h-[40px] leading-[40px] text-sm text-[#606266]"
                        placeholder="Số điện thoại..."
                        inputElement={
                          <i className="fas fa-phone-alt text-[#c0c4cc]"></i>
                        }
                        errorClassName="text-[#F56C6C] text-xs"
                      />
                    </FormControl>
                    <FormControl name="password">
                      <Input
                        width="auto"
                        className="!w-[376px] max-sm:!w-[275px] !max-w-none mb-[22px] max-sm:mb-2"
                        inputClassName="!rounded !border-[#DCDFE6] h-[40px] leading-[40px] text-sm text-[#606266]"
                        placeholder="Mật khẩu..."
                        inputElement={
                          <i className="fa fa-lock text-[#c0c4cc]"></i>
                        }
                        errorClassName="text-[#F56C6C] text-xs"
                        type="password"
                      />
                    </FormControl>
                    <FormControl name="confirmPassword">
                      <Input
                        width="auto"
                        className="!w-[376px] max-sm:!w-[275px] !max-w-none mb-[22px] max-sm:mb-1"
                        inputClassName="!rounded !border-[#DCDFE6] h-[40px] leading-[40px] text-sm text-[#606266]"
                        placeholder="Nhập lại mật khẩu..."
                        inputElement={
                          <i className="fa fa-lock text-[#c0c4cc]"></i>
                        }
                        errorClassName="text-[#F56C6C] text-xs"
                        type="password"
                      />
                    </FormControl>
                    <FormControl name="isConfirmed">
                      <Checkbox
                        value="true"
                        label={
                          <span className="text-sm text-[#606266]">
                            Đồng ý{" "}
                            <a
                              className="text-sm text-[#606266] font-bold hover:underline"
                              href="https://api.pcrender.com/view/term-of-service"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Điều Khoản Dịch Vụ
                            </a>
                          </span>
                        }
                        labelClassName="text-sm text-[#606266]"
                        errorClassName="text-xs text-[#F56C6C]"
                      />
                    </FormControl>
                    <Button
                      type="submit"
                      width="auto"
                      size="m"
                      containerClassName="mt-7 max-sm:mt-1"
                      label="ĐĂNG KÝ"
                      className="text-sm rounded-[20px] bg-[#409EFF] border-[#1E86E5] hover:bg-[#66B1FF] hover:border-[#66B1FF]"
                      labelClassName="font-medium"
                    />
                  </Form>
                </Formik>
                <div className="text-[14.4px] py-3 text-center">
                  <span className="text-[#000000DE]">Bạn đã có tài khoản?</span>
                  <span
                    className="cursor-pointer font-medium text-[#66B1FF] ml-2"
                    onClick={() => navigate("/login")}
                  >
                    Đăng nhập
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
