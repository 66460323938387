import { PortalDialogProps } from "@app/services/modal.service";
import Modal from "../modal/modal.component";

function SignUpModal({ portalDialogRef }: PortalDialogProps) {
  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren>
      <div className="w-[450px] px-8 py-5">
        <div className="mt-2 font-bold text-xl text-center">
          Xác minh tài khoản
        </div>
        <div className="text-center mt-5">
          Bạn hãy kiểm tra email để xác minh tài khoản
        </div>
        <div className="text-center mt-5 text-lg text-blue-500 font-bold">
          <a
            className="hover:underline"
            href="https://mail.google.com/mail"
            target="_blank"
            rel="noreferrer"
          >
            Đến email
          </a>
        </div>
      </div>
    </Modal>
  );
}

export default SignUpModal;
