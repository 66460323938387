import { DEFAULT_PAGE } from "@app/constants";
import { LogRequest, LogResponse } from "@app/types";
import HttpService from "@core/services/http/http.service";

class _LogService {
  public getLogs(page = DEFAULT_PAGE) {
    return HttpService.get<LogResponse>("/logs", {
      queryParams: {
        page,
      },
    });
  }

  public createLogByUser(body: LogRequest) {
    return HttpService.post<any>("/logs", {
      body: { ...body },
    });
  }
}

const LogService = new _LogService();

export default LogService;
