import { DEFAULT_PAGE } from "@app/constants";
import { VPS_Status } from "@app/constants";
import { CreateOrderVps, OrderVps, OrderVpsResponse } from "@app/types";
import HttpService from "@core/services/http/http.service";

class _OrderVpsService {
  public getOrdersVps(
    page = DEFAULT_PAGE,
    search = "",
    fromDate = "",
    toDate = "",
    status?: VPS_Status
  ) {
    const queryParams: any = {
      page,
      config_name: search,
      fromDate,
      toDate,
    };

    if (typeof status === "string" && status !== VPS_Status.ALREADY) {
      queryParams.status = status;
    }

    return HttpService.get<OrderVpsResponse>("/orders-vps", {
      queryParams,
    });
  }

  public createOrderVps(body: CreateOrderVps) {
    return HttpService.post<OrderVps>("/orders-vps", {
      body: {
        ...body,
      },
    });
  }

  public extendOrderVps(order_id: string, rental_period: number) {
    return HttpService.patch<OrderVps>("/orders-vps/" + order_id, {
      body: {
        rental_period,
      },
    });
  }

  public getExcelFileOrders(
    search = "",
    username = "",
    fromDate = "",
    toDate = "",
    status?: VPS_Status
  ) {
    const queryParams: any = {
      search,
      username,
      fromDate,
      toDate,
    };

    if (typeof status === "string" && status !== VPS_Status.ALREADY) {
      queryParams.status = status;
    }

    return HttpService.requestDownload("/orders-vps/orders_file", queryParams);
  }
}

const OrderVpsService = new _OrderVpsService();

export default OrderVpsService;
