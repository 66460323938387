import { PortalDialogProps } from "@app/services/modal.service";
import Modal from "../modal/modal.component";
import Button from "../button";
import { Form, Formik } from "formik";
import { FormControl } from "../form-control";
import Radio from "../radio";
import { VerificationMethod } from "@app/constants";

function VerificationModal({ portalDialogRef }: PortalDialogProps) {
  const handleSubmit = (values: {
    verificationMethod: VerificationMethod[];
  }) => {
    portalDialogRef.close({ verificationMethod: values.verificationMethod });
  };

  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren>
      <div className="w-[450px] px-8 py-5">
        <div className="font-bold text-xl border-b border-solid border-[#888888] pb-4">
          Phương thức xác thực
        </div>
        <div className="font-bold mt-4">Chọn phương thức phù hợp:</div>
        <Formik
          initialValues={{ verificationMethod: [VerificationMethod.EMAIL] }}
          onSubmit={handleSubmit}
        >
          <Form>
            <div className="mt-4 text-sm">
              <FormControl name="verificationMethod">
                <Radio
                  label="Email OTP"
                  className="!max-w-none text-sm py-1"
                  labelClassName="text-black"
                  value={VerificationMethod.EMAIL}
                />
              </FormControl>
            </div>
            <div className="mt-2">
              <span className="font-bold text-red-500">Lưu ý: </span>Việc{" "}
              <span>nhập mã OTP</span> để xác nhận người thao tác là chính chủ
              tài khoản
            </div>
            <div className="mt-5">
              <Button
                label="Tiếp tục"
                type="submit"
                width="fit-content"
                containerClassName="mx-auto"
                className="px-10 !bg-[#409EFF] hover:!bg-[#66B1FF] !rounded-[20px]"
                labelClassName="font-medium text-sm"
              />
            </div>
          </Form>
        </Formik>
      </div>
    </Modal>
  );
}

export default VerificationModal;
