import { Bank } from "@app/types";
import HttpService from "@core/services/http/http.service";

class _BankService {
  public getBanks() {
    return HttpService.get<Bank[]>("/banks");
  }
}

const BankService = new _BankService();

export default BankService;
