import Button from "@app/components/button";
import Checkbox from "@app/components/checkbox";
import { FormControl } from "@app/components/form-control";
import Input from "@app/components/input";
import { addToast } from "@app/components/toast/toast.service";
import VerificationCodeModal from "@app/components/verification-code-modal";
import VerificationModal from "@app/components/verification-modal";
import {
  ImageTypes,
  IsVerifyName,
  TypeCard,
  VerificationMethod,
} from "@app/constants";
import AccountService from "@app/services/http/account.service";
import AuthService from "@app/services/http/auth.service";
import { openPortalDialog } from "@app/services/modal.service";
import { GlobalState } from "@app/store";
import { MyInfoEpic } from "@app/store/my-info";
import { VerifyAccountInitialValues, VerifyAccountRequest } from "@app/types";
import { verifyValidationSchema } from "@app/validations/account.validation";
import useDestroy from "@core/hooks/use-destroy.hook";
import useObservable from "@core/hooks/use-observable.hook";
import { Form, Formik, FormikContextType } from "formik";
import { ChangeEvent, createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SignaturePad from "react-signature-pad-wrapper";

function IdentityVerification() {
  const [frontCard, setFrontCard] = useState<File>();
  const [frontCardError, setFrontCardError] = useState("");
  const [frontCardObj, setFrontCardObj] = useState("");

  const [backCard, setBackCard] = useState<File>();
  const [backCardError, setBackCardError] = useState("");
  const [backCardObj, setBackCardObj] = useState("");

  const [portraitCard, setPortraitCard] = useState<File>();
  const [portraitCardError, setPortraitCardError] = useState("");
  const [portraitCardObj, setPortraitCardObj] = useState("");

  const signaturePadRef = createRef<SignaturePad>();
  const formRef = createRef<FormikContextType<VerifyAccountInitialValues>>();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { destroy$ } = useDestroy();
  const { subscribeOnce } = useObservable();
  const { myInfo } = useSelector(selectMyInfo);

  useEffect(() => {
    if (myInfo.kyc === 0) {
      navigate("/");
    }
  }, [myInfo]);

  useEffect(() => {
    if (myInfo._id && formRef.current) {
      formRef.current.setFieldValue("email", myInfo.email);
      formRef.current.setFieldValue("phone", myInfo.phone);
    }
  }, [myInfo]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const cardName = e.target.name as TypeCard;

    if (!e.target.files?.length) {
      e.target.value = "";

      return;
    }

    if (!ImageTypes.includes(e.target.files[0].type)) {
      addToast({ text: "Chỉ tải lên định dạng ảnh", status: "inValid" });
    }

    const file = e.target.files[0];

    switch (cardName) {
      case TypeCard.FRONT_CARD: {
        setFrontCard(file);
        const frontCardObj = URL.createObjectURL(file);

        setFrontCardObj(frontCardObj);
        setFrontCardError("");
        break;
      }
      case TypeCard.BACK_CARD: {
        setBackCard(file);
        const backCardObj = URL.createObjectURL(file);

        setBackCardObj(backCardObj);
        setBackCardError("");
        break;
      }
      case TypeCard.PORTRAIT_CARD: {
        setPortraitCard(file);
        const portraitCardObj = URL.createObjectURL(file);

        setPortraitCardObj(portraitCardObj);
        setPortraitCardError("");
        break;
      }
    }

    e.target.value = "";
  };

  const handleSubmit = (values: VerifyAccountInitialValues) => {
    if (!frontCard) {
      setFrontCardError("Yêu cầu chọn ảnh tải lên");

      return;
    }

    if (!backCard) {
      setBackCardError("Yêu cầu chọn ảnh tải lên");

      return;
    }

    if (!portraitCard) {
      setPortraitCardError("Yêu cầu chọn ảnh tải lên");

      return;
    }

    const verificationModalObs = openPortalDialog(VerificationModal);

    verificationModalObs.afterClosed().subscribe((data) => {
      if (data?.verificationMethod) {
        if (data.verificationMethod[0] === VerificationMethod.EMAIL) {
          subscribeOnce(AuthService.getOtp(), () => {
            handleOpenVerificationCodeModal(values);
          });
        }
      }
    });
  };

  const handleOpenVerificationCodeModal = (
    values: VerifyAccountInitialValues
  ) => {
    if (!signaturePadRef.current) {
      return;
    }

    const signatureImageURL = signaturePadRef.current.toDataURL();

    const verificationCodeModalObs = openPortalDialog(VerificationCodeModal);

    verificationCodeModalObs.afterClosed().subscribe((data) => {
      if (data?.code) {
        if (frontCard && backCard && portraitCard && signatureImageURL) {
          const verifyAccountRequest: VerifyAccountRequest = {
            front_card: frontCard,
            back_card: backCard,
            portrait: portraitCard,
            signature_image: signatureImageURL,
            fullName: values.fullName,
            dob: values.dob,
            id: values.id,
            issueDate: values.issueDate,
            issuePlace: values.issuePlace,
            address: values.address,
            email: values.email,
            phone: values.phone,
            otp: data.code,
          };

          subscribeOnce(
            AccountService.verifyAccount(verifyAccountRequest),
            () => {
              addToast({ text: "Tải lên thành công" });
              dispatch(MyInfoEpic.fetchMyInfo({ destroy$ }));
            }
          );
        }
      }
    });
  };

  return (
    <div className="w-full p-2">
      <div className="font-bold text-xl">Xác minh KYC</div>
      <div className="flex">
        <div className="flex-1">
          <span className="font-bold">Trạng thái: </span>
          <span>{IsVerifyName[myInfo.isVerify]}</span>
        </div>
        <div className="flex-[2]">
          {myInfo.reasonFailKyc && (
            <div>
              Lý do thất bại: <span>{myInfo.reasonFailKyc}</span>
            </div>
          )}
        </div>
      </div>
      <div className="text-red-500">
        Lưu ý: Quý khách hãy cung cấp đầy đủ hình ảnh và thông tin theo mẫu bên
        dưới, sau đó ấn TẢI LÊN để hoàn tất xác minh
      </div>
      <div className="mt-2 flex justify-between pr-10 max-[1000px]:flex-wrap max-[1000px]:gap-4">
        <div className="w-72 max-xl:w-52">
          <div>1. CCCD mặt trước</div>
          <div className="w-72 max-xl:w-52 h-52 max-xl:h-32 border border-solid border-red-500">
            <img
              src={frontCardObj}
              alt="Chưa chọn ảnh"
              className="w-72 max-xl:w-52 h-[206px] max-xl:h-[126px]"
            />
          </div>
          <div className="mt-1">
            <input
              type="file"
              name={TypeCard.FRONT_CARD}
              className="w-72"
              onChange={handleFileChange}
            />
          </div>
          <div className="text-[#D60000B2] text-sm">{frontCardError}</div>
        </div>
        <div className="w-72 max-xl:w-52">
          <div>2. CCCD mặt sau</div>
          <div className="w-72 max-xl:w-52 h-52 max-xl:h-32 border border-solid border-red-500">
            <img
              src={backCardObj}
              alt="Chưa chọn ảnh"
              className="w-72 max-xl:w-52 h-[206px] max-xl:h-[126px]"
            />
          </div>
          <div className="mt-1">
            <input
              type="file"
              name={TypeCard.BACK_CARD}
              className="w-72"
              onChange={handleFileChange}
            />
          </div>
          <div className="text-[#D60000B2] text-sm">{backCardError}</div>
        </div>
        <div className="w-72 max-xl:w-52">
          <div>3. Ảnh chân dung</div>
          <div className="w-72 max-xl:w-52 h-52 max-xl:h-32 border border-solid border-red-500">
            <img
              src={portraitCardObj}
              alt="Chưa chọn ảnh"
              className="w-72 max-xl:w-52 h-[206px] max-xl:h-[126px]"
            />
          </div>
          <div className="mt-1">
            <input
              type="file"
              name={TypeCard.PORTRAIT_CARD}
              className="w-72"
              onChange={handleFileChange}
            />
          </div>
          <div className="text-[#D60000B2] text-sm">{portraitCardError}</div>
          <div>
            <p>Tay trái cầm CCCD/CMT tay phải cầm tờ giấy ghi nội dung: </p>
            <p>PCRENDER Ngày/tháng/năm hiện tại</p>
          </div>
        </div>
      </div>
      <div className="font-bold mt-2">Thông tin hợp đồng</div>
      <div>
        Xem hợp đồng{" "}
        <a
          href={myInfo.link_contract ? myInfo.link_contract : "#"}
          className="text-blue-500 hover:underline"
          target="_blank"
          rel="noreferrer"
        >
          tại đây
        </a>
      </div>
      <div className="font-bold mt-2">Điền thông tin ký hợp đồng</div>
      <div className="mt-2">
        <Formik
          initialValues={{
            fullName: "",
            dob: "",
            id: "",
            issueDate: "",
            issuePlace: "",
            address: "",
            email: "",
            phone: "",
            isConfirmed: [],
          }}
          onSubmit={handleSubmit}
          innerRef={formRef}
          validationSchema={verifyValidationSchema}
          validateOnChange
          validateOnBlur
        >
          <Form>
            <div className="flex gap-5 flex-wrap">
              <div className="flex gap-3 flex-col">
                <div className="flex items-center gap-2">
                  <div className="min-w-[80px]">Ông/Bà:</div>
                  <div className="min-w-[200px]">
                    <FormControl name="fullName">
                      <Input
                        className="text-sm !max-w-none"
                        errorClassName="text-sm"
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="min-w-[80px]">Số CCCD:</div>
                  <div className="min-w-[200px]">
                    <FormControl name="id">
                      <Input
                        className="text-sm !max-w-none"
                        errorClassName="text-sm"
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="min-w-[80px]">Địa chỉ:</div>
                  <div className="min-w-[200px]">
                    <FormControl name="address">
                      <Input
                        className="text-sm !max-w-none"
                        errorClassName="text-sm"
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="flex gap-3 flex-col">
                <div className="flex items-center gap-2">
                  <div className="min-w-[80px]">Ngày sinh:</div>
                  <div className="min-w-[200px]">
                    <FormControl name="dob">
                      <Input
                        type="date"
                        className="text-sm !max-w-none"
                        errorClassName="text-sm"
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="min-w-[80px]">Cấp ngày:</div>
                  <div className="min-w-[200px]">
                    <FormControl name="issueDate">
                      <Input
                        type="date"
                        className="text-sm !max-w-none"
                        errorClassName="text-sm"
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="min-w-[80px]">Email:</div>
                  <div className="min-w-[200px]">
                    <FormControl name="email">
                      <Input
                        className="text-sm !max-w-none"
                        errorClassName="text-sm"
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="flex gap-3 flex-col">
                <div className="flex items-center gap-2">
                  <div className="min-w-[80px]">Cấp tại:</div>
                  <div className="min-w-[200px]">
                    <FormControl name="issuePlace">
                      <Input
                        className="text-sm !max-w-none"
                        errorClassName="text-sm"
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="min-w-[80px]">Số ĐT:</div>
                  <div className="min-w-[200px]">
                    <FormControl name="phone">
                      <Input
                        className="text-sm !max-w-none"
                        errorClassName="text-sm"
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
            <div className="font-bold mt-2">Ký chữ ký điện tử</div>
            <div className="mt-2 w-8/12 max-xl:w-10/12 max-sm:w-full">
              <SignaturePad
                ref={signaturePadRef}
                canvasProps={{ className: "bg-white" }}
              />
            </div>
            <div className="mt-2">
              <FormControl name="isConfirmed">
                <Checkbox
                  value="true"
                  label="Tôi xác nhận"
                  errorClassName="text-sm"
                />
              </FormControl>
            </div>
            <div className="mt-2">
              <p>
                - Đã đọc, hiểu rõ và đồng ý các{" "}
                <a
                  className="text-sm text-[#606266] font-bold hover:underline"
                  href="https://api.pcrender.com/view/term-of-service"
                  target="_blank"
                  rel="noreferrer"
                >
                  điều khoản
                </a>{" "}
                trong hợp đồng
              </p>
              <p>
                - Đảm bảo tính xác thực và chịu trách nhiệm với các thông tin đã
                cung cấp: thông tin chủ thể, CMND/CCCD...
              </p>
              <p>
                - Đồng ý cập nhật chữ ký này vào file hợp đồng của bạn trên
                website{" "}
                <a
                  href="https://pcrender.com"
                  className="text-blue-500 hover:underline"
                >
                  pcrender.com
                </a>
              </p>
            </div>
            <div className="mt-2">
              <Button
                type="submit"
                label="TẢI LÊN"
                width="fit-content"
                className="px-7"
              />
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}

const selectMyInfo = (state: GlobalState) => state.myInfo;

export default IdentityVerification;
