export enum RechargeState {
  PENDING = 0,
  SUCCESSFULLY = 1,
  CANCELLED = 2,
  TIMEOUT = 3,
}

export const RechargeStateName = {
  [RechargeState.PENDING]: "Chờ xử lý",
  [RechargeState.SUCCESSFULLY]: "Thành công",
  [RechargeState.CANCELLED]: "Đã hủy",
  [RechargeState.TIMEOUT]: "Hết thời gian",
};
