import * as Yup from "yup";

export const loginValidationSchema = Yup.object().shape({
  username: Yup.string().required("Tên tài khoản không được để trống"),
  password: Yup.string().required("Mật khẩu không được để trống"),
});

export const signUpValidationSchema = Yup.object().shape({
  username: Yup.string()
    .required("Tên tài khoản không được để trống")
    .min(3, "Tên tài khoản không dưới 3 ký tự")
    .max(20, "Tên tài khoản không quá 20 ký tự"),
  fullName: Yup.string().required("Họ tên không được để trống"),
  email: Yup.string()
    .required("Email không được để trống")
    .email("Địa chỉ email không hợp lệ"),
  phone: Yup.string()
    .required("Số điện thoại không được để trống")
    .matches(/^[0-9]{10,12}$/, "Số điện thoại không hợp lệ"),
  password: Yup.string().required("Mật khẩu không được để trống"),
  confirmPassword: Yup.string()
    .required("Nhập lại mật khẩu không được để trống")
    .oneOf([Yup.ref("password")], "Nhập lại mật khẩu không khớp"),
  isConfirmed: Yup.array().min(1, "Yêu cầu xác nhận"),
});
