export enum OrderStatus {
  FINISHED = 0,
  BUSY = 1,
}

export const OrderStatusName = {
  [OrderStatus.FINISHED]: "Kết Thúc",
  [OrderStatus.BUSY]: "Đang Thuê",
};

export enum OrderExtend {
  NO = 0,
  YES = 1,
}

export const OrderExtendName = {
  [OrderExtend.NO]: "Không",
  [OrderExtend.YES]: "Có",
};
