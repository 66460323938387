import { PortalDialogProps } from "@app/services/modal.service";
import Modal from "../modal/modal.component";
import clsx from "clsx";
import ImageCard from "../image-card";
import Button from "../button";
import { useEffect, useState } from "react";
import { Category, Package } from "@app/types";
import useObservable from "@core/hooks/use-observable.hook";
import CategoryService from "@app/services/http/category.service";
import PackageService from "@app/services/http/package.service";
import Input from "../input";
import { addToast } from "../toast/toast.service";
import { CreateImageRequest } from "@app/types";
import ImageService from "@app/services/http/image.service";

function CreateImageModal({ portalDialogRef }: PortalDialogProps) {
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategoyId, setSelectedCategoyId] = useState("");
  const [packages, setPackages] = useState<Package[]>([]);
  const [selectedPackageId, setSelectedPackageId] = useState("");
  const [imageName, setImageName] = useState("");

  const { subscribeOnce, subscribeUntilDestroy } = useObservable();

  useEffect(() => {
    subscribeUntilDestroy(CategoryService.getCategories(), (categories) => {
      if (categories.length) {
        setCategories(categories);
        setSelectedCategoyId(categories[0]._id);
      } else {
        setCategories([]);
        setSelectedCategoyId("");
      }
    });
  }, []);

  useEffect(() => {
    if (selectedCategoyId) {
      subscribeUntilDestroy(
        PackageService.getPackages(selectedCategoyId),
        (packages) => {
          if (packages.length) {
            setPackages(packages);
            setSelectedPackageId(packages[0]._id);
          } else {
            setPackages([]);
            setSelectedPackageId("");
          }
        }
      );
    }
  }, [selectedCategoyId]);

  const handleSelectCategory = (category: Category) => {
    setSelectedCategoyId(category._id);
  };

  const handleSelectPackage = (item: Package) => {
    setSelectedPackageId(item._id);
  };

  const handleCreateImage = () => {
    if (!selectedPackageId) {
      addToast({ text: "Yêu cầu chọn package", status: "warn" });

      return;
    }

    if (!imageName) {
      addToast({ text: "Yêu cầu nhập tên PC", status: "warn" });

      return;
    }

    const createImageRequest: CreateImageRequest = {
      name: imageName,
      id_package: selectedPackageId,
    };

    subscribeOnce(ImageService.createImage(createImageRequest), (image) => {
      portalDialogRef.close({ image });
    });
  };

  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren>
      <div className="max-sm:w-[400px] max-[400px]:w-[300px] max-md:w-[600px] max-xl:w-[900px] max-[900px]:w-[700px] items-center w-[1200px] h-auto px-4 max-md:px-2 py-4">
        <div className="flex justify-center items-center gap-2">
          <i className="fa fa-plus text-xs text-[#1976D2]"></i>
          <span className="text-[14.4px] text-[#1976D2] font-semibold font-[Montserrat]">
            Tạo mới PC
          </span>
        </div>
        <div className="flex justify-center mt-3 text-[#606266] overflow-x-auto">
          {!!categories.length &&
            categories.map((category) => (
              <div
                key={category._id}
                className={clsx(
                  "w-[200px] h-7 border border-solid flex justify-center items-center",
                  "max-sm:w-[100px] max-sm:text-[8px] text-xs font-medium border-[#DCDFE6] cursor-pointer hover:text-[#409EFF]",
                  "first:rounded-l-[32px] last:rounded-r-[32px]",
                  {
                    "!border-[#409EFF] bg-[#409EFF] text-white hover:!text-white":
                      selectedCategoyId === category._id,
                  }
                )}
                onClick={() => handleSelectCategory(category)}
              >
                {category.name}
              </div>
            ))}
        </div>
        <div className="w-full mt-3 text-[#303133] flex gap-4 overflow-y-visible overflow-x-scroll pb-2">
          {!!packages.length &&
            packages.map((item) => (
              <ImageCard
                key={item._id}
                item={item}
                isSelected={selectedPackageId === item._id}
                onItemClick={(item) => handleSelectPackage(item)}
              />
            ))}
        </div>
        <div className="max-md:flex-col flex items-center justify-center gap-2 max-md:gap-1 mt-2 text-sm">
          <div>Tên PC:</div>
          <Input
            className="mt-1 text-sm"
            errorClassName="text-sm"
            value={imageName}
            onChange={(e) => setImageName(e.target.value)}
          />
        </div>
        <div className="mt-3">
          <Button
            label="Tiếp tục"
            width="fit-content"
            containerClassName="mx-auto"
            className="px-10 !bg-[#409EFF] hover:!bg-[#66B1FF] !rounded-[20px]"
            labelClassName="font-medium text-sm"
            onClick={handleCreateImage}
          />
        </div>
      </div>
    </Modal>
  );
}

export default CreateImageModal;
