import { DEFAULT_PAGE } from "@app/constants";
import { CreateImageRequest, Image, ImageResponse } from "@app/types";
import HttpService from "@core/services/http/http.service";

class _ImageService {
  public getImage(page = DEFAULT_PAGE) {
    return HttpService.get<ImageResponse>("/images", {
      queryParams: {
        page,
      },
    });
  }

  public createImage(createImageRequest: CreateImageRequest) {
    return HttpService.post<Image>("/images", {
      body: {
        ...createImageRequest,
      },
    });
  }

  public deleteImage(imageId: string) {
    return HttpService.delete<Image>(`/images/${imageId}`);
  }

  public wol(imageId: string) {
    return HttpService.get<Image>(`/images/wol/${imageId}`);
  }

  public reConnect(imageId: string) {
    return HttpService.get<any>(`/images/reconnect/${imageId}`);
  }
}

const ImageService = new _ImageService();

export default ImageService;
