import { Package } from "@app/types";
import HttpService from "@core/services/http/http.service";

class _PackageService {
  public getPackages(categoryId: string) {
    return HttpService.get<Package[]>(`/packages/categories/${categoryId}`);
  }

  public getAllPackages() {
    return HttpService.get<Package[]>("/packages");
  }
}

const PackageService = new _PackageService();

export default PackageService;
