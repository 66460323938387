export enum IsVerify {
  PENDING = 0,
  SUCCESS = 1,
  FAIL = 2,
}

export const IsVerifyName = {
  [IsVerify.PENDING]: "Đang chờ",
  [IsVerify.SUCCESS]: "Thành công",
  [IsVerify.FAIL]: "Thất bại",
};
