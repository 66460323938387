import { NormalSelectOptions } from "@app/components/select/select.type";

export enum BootImageUnit {
  HOUR = "hour",
  DAY = "day",
  MONTH = "month",
}

export const BootImageUnitName = {
  [BootImageUnit.HOUR]: "giờ",
  [BootImageUnit.DAY]: "ngày",
  [BootImageUnit.MONTH]: "tháng",
};

export const BOOT_IMAGE_UNIT_OPTIONS: NormalSelectOptions = [
  {
    label: BootImageUnitName[BootImageUnit.HOUR],
    value: BootImageUnit.HOUR,
  },
  {
    label: BootImageUnitName[BootImageUnit.DAY],
    value: BootImageUnit.DAY,
  },
  {
    label: BootImageUnitName[BootImageUnit.MONTH],
    value: BootImageUnit.MONTH,
  },
];
