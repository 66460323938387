import { LoaderFunction, redirect } from "react-router-dom";
import HttpService from "@core/services/http/http.service";

export const isAuthenticated: LoaderFunction = async ({ request }) => {
  const token = HttpService.getAccessToken();

  if (request.url.includes("/login") || request.url.includes("/register")) {
    if (token) {
      return redirect("/");
    }
  } else {
    if (!token) {
      return redirect("/home");
    }
  }

  return null;
};
