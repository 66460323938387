import {
  PortalDialogProps,
  openPortalDialog,
} from "@app/services/modal.service";
import Modal from "../modal/modal.component";
import { ImageItem } from "@app/types";
import ConfirmModal from "../confirm-modal";

function IPXoayModal({ portalDialogRef, portalData }: PortalDialogProps) {
  const image: ImageItem = portalData;

  const handleClickStart = (wan: string) => {
    const confirmDialog = openPortalDialog(ConfirmModal, {
      message: `Xác nhận chọn ${wan}`,
    });

    confirmDialog.afterClosed().subscribe((data) => {
      if (data?.isAccept) {
        portalDialogRef.close({ wan });
      }
    });
  };

  const handleClickChangeIP = (wan: string) => {
    portalDialogRef.close({ wan, change_ip: true });
  };

  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren>
      <div className="w-[900px] max-md:w-[500px] p-4">
        <h1 className="text-center m-4 font-bold text-xl">Đổi IP</h1>
        <div className="flex flex-col gap-4 flex-wrap justify-center">
          {image?.ssh?.router_xoay?.split(",").map((wan) => {
            const wanName = wan.split(" - ")[0].trim();
            const wan_selected = image?.ssh?.wan_selected;

            const is_started_by_any_one_not_me = wan_selected?.find(
              (value) =>
                value.name === wanName &&
                value?.account_id != image?.account._id
            );

            const indexStartByMe = wan_selected?.findIndex(
              (value) =>
                value.name === wanName &&
                value?.account_id == image?.account._id
            );

            const is_started_by_me =
              indexStartByMe !== undefined && indexStartByMe >= 0;

            return (
              <div key={wan} className="flex flex-wrap justify-between">
                <div
                  className={`flex gap-2 items-center w-[70%] text-center text-white px-2 rounded ${
                    is_started_by_any_one_not_me
                      ? "bg-gray-500"
                      : "bg-[#42A5F5]"
                  }`}
                >
                  {wan}
                  <div>
                    (IP:{" "}
                    {is_started_by_me ? wan_selected[indexStartByMe].ip : ""})
                  </div>
                </div>
                {is_started_by_me ? (
                  <button
                    className="w-[20%] bg-[#ff2929] hover:bg-[#0f63a7] text-white px-2 rounded"
                    onClick={() => handleClickChangeIP(wan)}
                  >
                    Đổi IP
                  </button>
                ) : is_started_by_any_one_not_me ? (
                  ""
                ) : (
                  <button
                    className="w-[20%] bg-[#42A5F5] hover:bg-[#0f63a7] text-white px-2 rounded"
                    onClick={() => handleClickStart(wan)}
                  >
                    Start
                  </button>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
}

export default IPXoayModal;
