import { SshCli, Tunnel } from "@app/types/ssh.type";
import HttpService from "@core/services/http/http.service";

class _SshService {
  public sshCliAddMangle(sshCliRequest: SshCli) {
    return HttpService.post<any>("/sshs/cli-add-mangle", {
      body: { ...sshCliRequest },
    });
  }

  public sshCliStartIPXoay(sshCliRequest: SshCli) {
    return HttpService.post<any>("/sshs/start-ip-xoay", {
      body: { ...sshCliRequest },
    });
  }

  public sshCliChangeIPXoay(sshCliRequest: SshCli) {
    return HttpService.post<any>("/sshs/change-ip-xoay", {
      body: { ...sshCliRequest },
    });
  }

  public chooseTunnel(tunnelRequest: Tunnel) {
    if (tunnelRequest.os === "mobile") {
      return HttpService.get<any>(
        `/sshs/choose-tunnel?name=${tunnelRequest.name}&allowIp=${tunnelRequest.allowIp}&os=${tunnelRequest.os}&image_id=${tunnelRequest.image_id}&api_tunnel=${tunnelRequest.api_tunnel}`
      );
    } else {
      return HttpService.requestDownload(
        `/sshs/choose-tunnel?name=${tunnelRequest.name}&allowIp=${tunnelRequest.allowIp}&os=${tunnelRequest.os}&image_id=${tunnelRequest.image_id}&api_tunnel=${tunnelRequest.api_tunnel}`
      );
    } 
  }

  public sshCliDeleteMangle(sshCliRequest: SshCli) {
    return HttpService.post<any>("/sshs/cli-delete-mangle", {
      body: { ...sshCliRequest },
    });
  }
}

const SshService = new _SshService();

export default SshService;
