import {
  CreateRechargeRequest,
  CreateRechargeResponse,
  Recharge,
  RechargeResponse,
} from "@app/types";
import HttpService from "@core/services/http/http.service";

class _RechargeService {
  public getRecharges(page: number) {
    return HttpService.get<RechargeResponse>(`/recharges?page=${page}`);
  }

  public createRecharge(createRechargeRequest: CreateRechargeRequest) {
    return HttpService.post<CreateRechargeResponse>("/recharges/request", {
      body: { ...createRechargeRequest },
    });
  }

  public cancelRecharge(rechargeId: string) {
    return HttpService.patch<Recharge>(`/recharges/${rechargeId}`);
  }
}

const RechargeService = new _RechargeService();

export default RechargeService;
